import {
  ApiProperty,
  IsDateString,
  IsEnum,
  IsNotEmpty,
  IsString,
  IsUUID,
  OmitType,
  PartialType,
} from './decorators-nest';
import { FertilityTestDto } from './fertility-test.interface';

/**
 * Bemum fertility test review
 */
export class ReviewFtDto {
  @ApiProperty({
    description: 'UUID of the review',
    type: 'string',
    format: 'uuid',
    example: '124ed8db-25e5-47d7-a4f3-b1d9fa934650',
  })
  id: string;

  @ApiProperty({
    description: 'Reviewer',
  })
  fertilityTest: FertilityTestDto;

  @ApiProperty({
    description: 'The content of the review',
    example: '<p>Crucifix de sacrament de colon de sacristi.</p>',
  })
  content: string;

  @ApiProperty({
    description: 'Creation timestamp',
  })
  @IsDateString()
  createdDate: Date;

  @ApiProperty({
    description: 'Updation timestamp',
  })
  updatedDate: Date;
}

/**
 * Create review payload
 */

export class CreateReviewFtDto {
  @ApiProperty({
    description: 'Id of the reviewee',
    type: 'string',
    format: 'uuid',
    example: '124ed8db-25e5-47d7-a4f3-b1d9fa934650',
  })
  @IsUUID()
  @IsString()
  fertilityTestId: string;

  @ApiProperty({
    description: 'Actual review',
    example: '<p>Crucifix de sacrament de colon de sacristi</p>',
  })
  @IsString()
  @IsNotEmpty()
  content: string;
}

/**
 * Update review payload
 */
export class UpdateReviewFtDto extends PartialType(OmitType(CreateReviewFtDto, ['fertilityTestId'])) {}
