import { IsBoolean, IsNumber, IsOptional, IsString, IsUUID, Max, Min, ValidateIf } from './decorators-nest';
import { ApiProperty, OmitType } from './decorators-nest';

/**
 * Payload to create a Health Survey
 * @todo: translate each description in english
 */
export class CreateHealthSurveyDto {
  @ApiProperty({
    description: 'related patient',
    format: 'uuid',
  })
  @IsUUID()
  patient: string;

  /* ########### BLOOD TEST SECTION ############## */

  @ApiProperty({
    description: 'Bilan sanguin',
    required: true,
  })
  @IsBoolean()
  BLOOD_TEST: boolean;

  @ApiProperty({
    required: false,
    description: 'Cholestérol, total (g/L). Required if BLOOD_TEST is true.',
    example: 5.01,
    minimum: 0,
    maximum: 10,
    exclusiveMinimum: false,
    exclusiveMaximum: false,
  })
  @IsNumber()
  @Min(0)
  @Max(10)
  @ValidateIf((o: CreateHealthSurveyDto) => o.BLOOD_TEST === true)
  @IsOptional()
  BLOOD_TEST_CHOL: number;

  @ApiProperty({
    required: false,
    description: 'Cholestérol, HDL (g/L). Required if BLOOD_TEST is true.',
    example: 5.01,
    minimum: 0,
    maximum: 10,
    exclusiveMinimum: false,
    exclusiveMaximum: false,
  })
  @IsNumber()
  @Min(0)
  @Max(10)
  @ValidateIf((o: CreateHealthSurveyDto) => o.BLOOD_TEST === true)
  @IsOptional()
  BLOOD_TEST_HDL_CHOL: number;

  @ApiProperty({
    required: false,
    description: 'Cholestérol, LDL (g/L). Required if BLOOD_TEST is true.',
    example: 5.01,
    minimum: 0,
    maximum: 10,
    exclusiveMinimum: false,
    exclusiveMaximum: false,
  })
  @IsNumber()
  @Min(0)
  @Max(10)
  @ValidateIf((o: CreateHealthSurveyDto) => o.BLOOD_TEST === true)
  @IsOptional()
  BLOOD_TEST_LDL_CHOL: number;

  @ApiProperty({
    required: false,
    description: 'Triglycérides (g/L). Required if BLOOD_TEST is true.',
    example: 5.01,
    minimum: 0,
    maximum: 10,
    exclusiveMinimum: false,
    exclusiveMaximum: false,
  })
  @IsNumber()
  @Min(0)
  @Max(10)
  @ValidateIf((o: CreateHealthSurveyDto) => o.BLOOD_TEST === true)
  @IsOptional()
  BLOOD_TEST_TRIGLYCERIDES: number;

  @ApiProperty({
    required: false,
    description: 'Glycémie à jeun (g/L). Required if BLOOD_TEST is true.',
    example: 5.01,
    minimum: 0,
    maximum: 10,
    exclusiveMinimum: false,
    exclusiveMaximum: false,
  })
  @IsNumber()
  @Min(0)
  @Max(10)
  @ValidateIf((o: CreateHealthSurveyDto) => o.BLOOD_TEST === true)
  @IsOptional()
  BLOOD_TEST_GLUCOSE: number;

  @ApiProperty({
    required: false,
    description: 'Cortisol (nmol/L). Required if BLOOD_TEST is true.',
    example: 13.42,
    minimum: 0,
    maximum: 25,
    exclusiveMinimum: false,
    exclusiveMaximum: false,
  })
  @IsNumber()
  @Min(0)
  @Max(25)
  @ValidateIf((o: CreateHealthSurveyDto) => o.BLOOD_TEST === true)
  @IsOptional()
  BLOOD_TEST_CORTISOL: number;

  /* ########### MEDICAL HISTORY SECTION ############## */

  @ApiProperty({
    required: true,
    description: 'Maladie diagnostiquée',
  })
  @IsBoolean()
  MED_HISTORY_DIAGNOSED_DISEASE: boolean;

  @ApiProperty({
    required: false,
    description: 'Maladie cardiovasculaire diagnostiquée. Required if MED_HISTORY_DIAGNOSED_DISEASE is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.MED_HISTORY_DIAGNOSED_DISEASE === true)
  MED_HISTORY_CARDIOVASCULAR: boolean;

  @ApiProperty({
    required: false,
    description: 'Hypertension artérielle diagnostiquée. Required if MED_HISTORY_DIAGNOSED_DISEASE is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.MED_HISTORY_DIAGNOSED_DISEASE === true)
  MED_HISTORY_HIGH_BLOOD_PRESSURE: boolean;

  @ApiProperty({
    required: false,
    description: 'Maladie inflammatoire chronique diagnostiquée. Required if MED_HISTORY_DIAGNOSED_DISEASE is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.MED_HISTORY_DIAGNOSED_DISEASE === true)
  MED_HISTORY_CHRONIC_INFLAMMATORY_DISEASE: boolean;

  @ApiProperty({
    required: false,
    description: 'Hypothyroïdie. Required if MED_HISTORY_DIAGNOSED_DISEASE is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.MED_HISTORY_DIAGNOSED_DISEASE === true)
  MED_HISTORY_HYPOTHYROIDISM: boolean;

  @ApiProperty({
    required: false,
    description: 'Hyperthoroïdie. Required if MED_HISTORY_DIAGNOSED_DISEASE is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.MED_HISTORY_DIAGNOSED_DISEASE === true)
  MED_HISTORY_HYPERTHYROIDISM: boolean;

  @ApiProperty({
    required: false,
    description: 'Autre maladie. Optional but if defined, must be a string.',
    example: 'covid-19',
  })
  @IsString()
  @IsOptional()
  MED_HISTORY_DIAGNOSED_DISEASE_LABEL: string;

  @ApiProperty({
    required: false,
    description: 'Antécédents surpoids. Required if MED_HISTORY_DIAGNOSED_DISEASE is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.MED_HISTORY_DIAGNOSED_DISEASE === true)
  MED_FAMILY_HISTORY_OVERWEIGHT: boolean;

  @ApiProperty({
    required: false,
    description: 'Antécédents diabète. Required if MED_HISTORY_DIAGNOSED_DISEASE is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.MED_HISTORY_DIAGNOSED_DISEASE === true)
  MED_FAMILY_HISTORY_DIABETES: boolean;

  @ApiProperty({
    required: false,
    description: 'Antécédents hypercholestérolémie. Required if MED_HISTORY_DIAGNOSED_DISEASE is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.MED_HISTORY_DIAGNOSED_DISEASE === true)
  MED_FAMILY_HISTORY_HYPERCHOLESTEROLEMIA: boolean;

  @ApiProperty({
    required: false,
    description: 'Antécédents hypertriglycéridémie. Required if MED_HISTORY_DIAGNOSED_DISEASE is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.MED_HISTORY_DIAGNOSED_DISEASE === true)
  MED_FAMILY_HISTORY_HYPERTRIGLYCERIDEMIA: boolean;

  @ApiProperty({
    required: false,
    description: 'Antécédents maladie cardiovasculaire. Required if MED_HISTORY_DIAGNOSED_DISEASE is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.MED_HISTORY_DIAGNOSED_DISEASE === true)
  MED_FAMILY_HISTORY_CARDIOVASCULAR: boolean;

  /* ########### MEDICAL TREATMENT SECTION ############## */

  @ApiProperty({
    required: true,
    description: 'Traitement médicamenteux',
  })
  @IsBoolean()
  MED_TREATMENT: boolean;

  @ApiProperty({
    required: false,
    description: 'Anxiolytiques. Required if MED_TREATMENT is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.MED_TREATMENT === true)
  MED_TREATMENT_ANXIOLYTICS: boolean;

  @ApiProperty({
    required: false,
    description: 'Antidépresseurs. Required if MED_TREATMENT is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.MED_TREATMENT === true)
  MED_TREATMENT_ANTIDEPRESSANTS: boolean;

  @ApiProperty({
    required: false,
    description: 'Neuroleptiques. Required if MED_TREATMENT is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.MED_TREATMENT === true)
  MED_TREATMENT_NEUROLEPTICS: boolean;

  @ApiProperty({
    required: false,
    description: 'Corticoïdes. Required if MED_TREATMENT is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.MED_TREATMENT === true)
  MED_TREATMENT_CORTICOSTEROIDS: boolean;

  @ApiProperty({
    required: false,
    description: 'Somnifères. Required if MED_TREATMENT is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.MED_TREATMENT === true)
  MED_TREATMENT_SLEEPING_PILL: boolean;

  @ApiProperty({
    required: false,
    description: 'Beta-bloquants. Required if MED_TREATMENT is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.MED_TREATMENT === true)
  MED_TREATMENT_BETA_BLOCKERS: boolean;

  @ApiProperty({
    required: false,
    description: 'Laxatifs. Required if MED_TREATMENT is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.MED_TREATMENT === true)
  MED_TREATMENT_LAXATIVES: boolean;

  @ApiProperty({
    required: false,
    description: 'Inhibiteurs de la pompe à protons. Required if MED_TREATMENT is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.MED_TREATMENT === true)
  MED_TREATMENT_PPI: boolean;

  @ApiProperty()
  @ApiProperty({
    required: false,
    description: 'Autre médicament',
    example: 'perlimpinpin powder',
  })
  @IsString()
  @IsOptional()
  @ValidateIf((o: CreateHealthSurveyDto) => o.MED_TREATMENT === true)
  MED_TREATMENT_OTHER: string;

  /* ########### DEFICIENCY SECTION ############## */

  @ApiProperty({
    required: true,
    description: 'Carence alimentaire',
  })
  @IsBoolean()
  DECLARATIVE_DEFICIENCY: boolean;

  @ApiProperty({
    required: false,
    description: 'Carence Vitamine B2. Required if DECLARATIVE_DEFICIENCY is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.DECLARATIVE_DEFICIENCY === true)
  DECLARATIVE_DEFICIENCY_VITAMINE_B2: boolean;

  @ApiProperty({
    required: false,
    description: 'Carence Vitamine B3. Required if DECLARATIVE_DEFICIENCY is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.DECLARATIVE_DEFICIENCY === true)
  DECLARATIVE_DEFICIENCY_VITAMINE_B3: boolean;

  @ApiProperty({
    required: false,
    description: 'Carence Vitamine B9. Required if DECLARATIVE_DEFICIENCY is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.DECLARATIVE_DEFICIENCY === true)
  DECLARATIVE_DEFICIENCY_VITAMINE_B9: boolean;

  @ApiProperty({
    required: false,
    description: 'Carence Vitamine B12. Required if DECLARATIVE_DEFICIENCY is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.DECLARATIVE_DEFICIENCY === true)
  DECLARATIVE_DEFICIENCY_VITAMINE_B12: boolean;

  @ApiProperty({
    required: false,
    description: 'Carence Vitamine D. Required if DECLARATIVE_DEFICIENCY is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.DECLARATIVE_DEFICIENCY === true)
  DECLARATIVE_DEFICIENCY_VITAMINE_D: boolean;

  @ApiProperty({
    required: false,
    description: 'Carence Calcium. Required if DECLARATIVE_DEFICIENCY is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.DECLARATIVE_DEFICIENCY === true)
  DECLARATIVE_DEFICIENCY_CALCIUM: boolean;

  @ApiProperty({
    required: false,
    description: 'Carence Fer. Required if DECLARATIVE_DEFICIENCY is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.DECLARATIVE_DEFICIENCY === true)
  DECLARATIVE_DEFICIENCY_IRON: boolean;

  @ApiProperty({
    required: false,
    description: 'Carence Magnésium. Required if DECLARATIVE_DEFICIENCY is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.DECLARATIVE_DEFICIENCY === true)
  DECLARATIVE_DEFICIENCY_MAGNESIUM: boolean;

  @ApiProperty({
    required: false,
    description: 'Carence Zinc. Required if DECLARATIVE_DEFICIENCY is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.DECLARATIVE_DEFICIENCY === true)
  DECLARATIVE_DEFICIENCY_ZINC: boolean;

  @ApiProperty({
    required: false,
    description: 'Carence Autre. Required if DECLARATIVE_DEFICIENCY is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.DECLARATIVE_DEFICIENCY === true)
  DECLARATIVE_DEFICIENCY_OTHER: boolean;

  /* ########### DIGESTIVE ISSUES SECTION ############## */

  @ApiProperty({
    required: true,
    description: 'Inconfort digestif',
  })
  @IsBoolean()
  DIGESTIVES_ISSUES: boolean;

  @ApiProperty({
    required: false,
    description: 'Troubles du transit. Required if DIGESTIVES_ISSUES is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.DIGESTIVES_ISSUES === true)
  DIGESTIVES_ISSUES_DIARRHEA_CONSTIPATION: boolean;

  @ApiProperty({
    required: false,
    description: 'Ballonnements. Required if DIGESTIVES_ISSUES is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.DIGESTIVES_ISSUES === true)
  DIGESTIVES_ISSUES_BLOATING: boolean;

  @ApiProperty({
    required: false,
    description: 'Douleurs ou spasmes intestinaux. Required if DIGESTIVES_ISSUES is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.DIGESTIVES_ISSUES === true)
  DIGESTIVES_ISSUES_PAIN_SPASMS: boolean;

  @ApiProperty({
    required: false,
    description: "Brûlures d'estomac et/ou reflux acides. Required if DIGESTIVES_ISSUES is true.",
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.DIGESTIVES_ISSUES === true)
  DIGESTIVES_ISSUES_HEARTBURN_GASTRIC_REFLUX: boolean;

  @ApiProperty({
    required: false,
    description: 'Autre. Required if DIGESTIVES_ISSUES is true.',
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.DIGESTIVES_ISSUES === true)
  DIGESTIVES_ISSUES_ELSE: boolean;

  @ApiProperty({
    required: false,
    description:
      "Inconfort digestif lié à l'alimentation. Required if DIGESTIVES_ISSUES is true and MED_TREATMENT is false.",
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.DIGESTIVES_ISSUES === true && o.MED_TREATMENT === false)
  DIGESTIVE_ISSUE_DIET: boolean;

  @ApiProperty({
    required: false,
    description:
      "Inconfort digestif lié au traitement ou à l'alimentation. Required if DIGESTIVES_ISSUES is true and MED_TREATMENT is true.",
  })
  @IsBoolean()
  @ValidateIf((o: CreateHealthSurveyDto) => o.DIGESTIVES_ISSUES === true && o.MED_TREATMENT === true)
  DIGESTIVE_ISSUE_MED_TREATMENT_OR_DIET: boolean;

  @ApiProperty({
    required: true,
    description: 'Menstruations abondantes',
  })
  @IsBoolean()
  ABUNDANT_MENSTRUATIONS: boolean;
}

/**
 * Representation of a health survey
 */
export class HealthSurveyDto extends OmitType(CreateHealthSurveyDto, ['patient'] as const) {
  @ApiProperty({
    type: 'string',
    format: 'uuid',
  })
  id: string;

  @ApiProperty({
    format: 'date-time',
  })
  createdDate: string;

  @ApiProperty({
    format: 'date-time',
  })
  updatedDate: string;
}

/**
 * Payload to create a Follow Up Health Survey
 */
export class CreateFollowUpHealthSurveyDto extends OmitType(CreateHealthSurveyDto, [
  'MED_HISTORY_DIAGNOSED_DISEASE',
  'MED_HISTORY_CARDIOVASCULAR',
  'MED_HISTORY_HIGH_BLOOD_PRESSURE',
  'MED_HISTORY_CHRONIC_INFLAMMATORY_DISEASE',
  'MED_HISTORY_HYPOTHYROIDISM',
  'MED_HISTORY_HYPERTHYROIDISM',
  'MED_HISTORY_DIAGNOSED_DISEASE_LABEL',
  'MED_FAMILY_HISTORY_OVERWEIGHT',
  'MED_FAMILY_HISTORY_DIABETES',
  'MED_FAMILY_HISTORY_HYPERCHOLESTEROLEMIA',
  'MED_FAMILY_HISTORY_HYPERTRIGLYCERIDEMIA',
  'MED_FAMILY_HISTORY_CARDIOVASCULAR',
  'DECLARATIVE_DEFICIENCY',
  'DECLARATIVE_DEFICIENCY_VITAMINE_B2',
  'DECLARATIVE_DEFICIENCY_VITAMINE_B3',
  'DECLARATIVE_DEFICIENCY_VITAMINE_B9',
  'DECLARATIVE_DEFICIENCY_VITAMINE_B12',
  'DECLARATIVE_DEFICIENCY_VITAMINE_D',
  'DECLARATIVE_DEFICIENCY_CALCIUM',
  'DECLARATIVE_DEFICIENCY_IRON',
  'DECLARATIVE_DEFICIENCY_MAGNESIUM',
  'DECLARATIVE_DEFICIENCY_ZINC',
  'DECLARATIVE_DEFICIENCY_OTHER',
  'ABUNDANT_MENSTRUATIONS',
] as const) {}

/**
 * Payload to patch a Sign Up Health Survey
 */
export class UpdateHealthSurveyDto extends OmitType(CreateHealthSurveyDto, ['patient'] as const) {}
