import { ApiProperty, IsBoolean, IsNumber, IsUUID, Max, Min, OmitType, ValidateIf } from './decorators-nest';

/**
 * Payload to create a Sleep Survey
 */
export class CreateSleepSurveyDto {
  @ApiProperty({
    description: 'related patient',
    format: 'uuid',
  })
  @IsUUID()
  patient: string;

  @ApiProperty({ required: true, example: 8 })
  @IsNumber()
  @Min(1)
  @Max(15)
  SLEEP_DURATION_DAILY: number;

  @ApiProperty({ required: true })
  @IsBoolean()
  FALL_ASLEEP_DIFFICULTIES_WORRIED: boolean;

  @ApiProperty({ required: true })
  @IsBoolean()
  MIDDLE_OF_NIGHT_AWAKENING: boolean;

  @ApiProperty({ required: true })
  @IsBoolean()
  EARLY_SPONTANEOUS_AWAKENING: boolean;

  @ApiProperty({ required: true })
  @IsBoolean()
  VERY_ACTIVE_LIFESTYLE: boolean;

  @ApiProperty({ required: true })
  @IsBoolean()
  SLEEP_DEPRIVATION: boolean;

  @ApiProperty({ required: false })
  @IsBoolean()
  @ValidateIf((o: CreateSleepSurveyDto) => o.SLEEP_DEPRIVATION === true)
  SLEEP_DEPRIVATION_LACK_TIME: boolean;

  @ApiProperty({ required: true })
  @IsBoolean()
  NEED_COFFEE_STIMULANT: boolean;

  @ApiProperty({ required: true })
  @IsBoolean()
  PHYSICAL_FATIGUE: boolean;

  @ApiProperty({ required: false })
  @IsBoolean()
  @ValidateIf((o: CreateSleepSurveyDto) => o.PHYSICAL_FATIGUE === true)
  PHYSICAL_FATIGUE_ON_WAKING: boolean;

  @ApiProperty({ required: true })
  @IsBoolean()
  FATIGUE_PHYSICAL_EFFORT: boolean;
}

/**
 * Representation of a mood survey
 */
export class SleepSurveyDto extends OmitType(CreateSleepSurveyDto, ['patient'] as const) {
  @ApiProperty({
    type: 'string',
    format: 'uuid',
  })
  id: string;

  @ApiProperty({
    format: 'date-time',
  })
  createdDate: string;

  @ApiProperty({
    format: 'date-time',
  })
  updatedDate: string;
}

/**
 * Payload to create a Follow Up Sleep Survey
 */
export class CreateFollowUpSleepSurveyDto extends CreateSleepSurveyDto {
  @ApiProperty()
  @IsBoolean()
  CHANGE_STATE_FATIGUE: boolean;

  @ValidateIf((o: CreateFollowUpSleepSurveyDto) => o.CHANGE_STATE_FATIGUE === true)
  SLEEP_DURATION_DAILY: number;

  @ValidateIf((o: CreateFollowUpSleepSurveyDto) => o.CHANGE_STATE_FATIGUE === true)
  FALL_ASLEEP_DIFFICULTIES_WORRIED: boolean;

  @ValidateIf((o: CreateFollowUpSleepSurveyDto) => o.CHANGE_STATE_FATIGUE === true)
  MIDDLE_OF_NIGHT_AWAKENING: boolean;

  @ValidateIf((o: CreateFollowUpSleepSurveyDto) => o.CHANGE_STATE_FATIGUE === true)
  EARLY_SPONTANEOUS_AWAKENING: boolean;

  @ValidateIf((o: CreateFollowUpSleepSurveyDto) => o.CHANGE_STATE_FATIGUE === true)
  VERY_ACTIVE_LIFESTYLE: boolean;

  @ValidateIf((o: CreateFollowUpSleepSurveyDto) => o.CHANGE_STATE_FATIGUE === true)
  SLEEP_DEPRIVATION: boolean;

  @ValidateIf((o: CreateFollowUpSleepSurveyDto) => o.CHANGE_STATE_FATIGUE === true)
  SLEEP_DEPRIVATION_LACK_TIME: boolean;

  @ValidateIf((o: CreateFollowUpSleepSurveyDto) => o.CHANGE_STATE_FATIGUE === true)
  NEED_COFFEE_STIMULANT: boolean;

  @ValidateIf((o: CreateFollowUpSleepSurveyDto) => o.CHANGE_STATE_FATIGUE === true)
  PHYSICAL_FATIGUE: boolean;

  @ValidateIf((o: CreateFollowUpSleepSurveyDto) => o.CHANGE_STATE_FATIGUE === true)
  PHYSICAL_FATIGUE_ON_WAKING: boolean;

  @ValidateIf((o: CreateFollowUpSleepSurveyDto) => o.CHANGE_STATE_FATIGUE === true)
  FATIGUE_PHYSICAL_EFFORT: boolean;
}

/**
 * Payload to update a Sign Up Sleep Survey
 */
export class UpdateSleepSurveyDto extends OmitType(CreateSleepSurveyDto, ['patient'] as const) {}
