import {
  ApiProperty,
  IsBoolean,
  IsDateString,
  IsInt,
  IsNumber,
  IsString,
  IsUUID,
  Max,
  Min,
  OmitType,
  registerDecorator,
  ValidateIf,
  ValidationOptions,
} from './decorators-nest';

/**
 * Custom ./decorators-nest decorator.
 * Similar to @MaxDate but with ISO date strings instead
 * of regular JavaScript Date
 */
export function MaxDateString(date: string, validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: 'MaxDateString',
      target: object.constructor,
      options: validationOptions,
      propertyName: propertyName,
      validator: {
        validate(value) {
          return typeof value === 'string' && typeof date === 'string' && new Date(value) <= new Date(date);
        },
      },
    });
  };
}
/**
 * Payload to create a Profile Survey
 * @todo: translate each description in english
 */
export class CreateProfileSurveyDto {
  @ApiProperty({
    description: 'related patient',
    format: 'uuid',
  })
  @IsUUID()
  patient: string;

  @ApiProperty({
    format: 'date-time',
    description: 'Minimum 18 years ago from today',
  })
  @IsDateString()
  @MaxDateString(nowMinus18Years(), {
    message: `maximal allowed date for BIRTHDATE is ${nowMinus18Years()}`,
  })
  BIRTHDATE: string;

  @ApiProperty()
  @IsInt()
  DEPARTEMENT_OF_RESIDENCE: number;

  @ApiProperty()
  @IsBoolean()
  PROFESSIONAL_ACTIVITY: boolean;

  @ApiProperty()
  @IsBoolean()
  @ValidateIf((o: CreateProfileSurveyDto) => o.PROFESSIONAL_ACTIVITY === true)
  PROFESSIONAL_ACTIVITY_HOME_OFFICE: boolean;

  @ApiProperty()
  @IsBoolean()
  @ValidateIf((o: CreateProfileSurveyDto) => o.PROFESSIONAL_ACTIVITY === true)
  PROFESSIONAL_ACTIVITY_BUSINESS_TRIPS: boolean;

  @ApiProperty()
  @IsBoolean()
  @ValidateIf((o: CreateProfileSurveyDto) => o.PROFESSIONAL_ACTIVITY === true)
  PROFESSIONAL_ACTIVITY_STAGGERED_HOURS: boolean;

  @ApiProperty()
  @IsBoolean()
  @ValidateIf((o: CreateProfileSurveyDto) => o.PROFESSIONAL_ACTIVITY === true)
  PROFESSIONAL_ACTIVITY_SEDENTARY: boolean;

  @ApiProperty()
  @IsBoolean()
  @ValidateIf((o: CreateProfileSurveyDto) => o.PROFESSIONAL_ACTIVITY === true)
  PROFESSIONAL_ACTIVITY_ON_FOOT: boolean;

  @ApiProperty()
  @IsBoolean()
  @ValidateIf((o: CreateProfileSurveyDto) => o.PROFESSIONAL_ACTIVITY === true)
  PROFESSIONAL_ACTIVITY_PHYSICAL: boolean;

  @ApiProperty()
  @IsBoolean()
  FOOD_SUPPLEMENT: boolean;

  @ApiProperty()
  @IsString()
  @ValidateIf((o: CreateProfileSurveyDto) => o.FOOD_SUPPLEMENT === true)
  FOOD_SUPPLEMENT_LABEL: string;

  @ApiProperty()
  @IsBoolean()
  COACHING_GOALS_WEIGHT_LOSS: boolean;

  @ApiProperty()
  @IsBoolean()
  COACHING_GOALS_IMPROVE_DIET: boolean;

  @ApiProperty()
  @IsBoolean()
  COACHING_GOALS_BLOOD_TEST: boolean;

  @ApiProperty()
  @IsBoolean()
  COACHING_GOALS_LIFESTYLE: boolean;

  @ApiProperty()
  @IsBoolean()
  COACHING_GOALS_ELSE: boolean;

  @ApiProperty({
    example: 100,
  })
  @IsInt()
  @Min(100)
  @Max(250)
  HEIGHT: number;

  @ApiProperty({ example: 40 })
  @IsNumber()
  @Min(40)
  @Max(250)
  WEIGHT: number;

  @ApiProperty({ example: 50 })
  @IsNumber()
  @Min(50)
  @Max(250)
  WAIST: number;

  @ApiProperty({ example: 50 })
  @IsNumber()
  @Min(50)
  @Max(250)
  HIPS: number;

  @ApiProperty()
  @IsBoolean()
  CHILDHOOD_OVERWEIGHT: boolean;

  @ApiProperty({ example: 40 })
  @IsNumber()
  @Min(40)
  @Max(250)
  WEIGHT_MAXIMUM: number;

  @ApiProperty({ example: 40 })
  @IsNumber()
  @Min(40)
  @Max(250)
  WEIGHT_MINIMUM: number;

  @ApiProperty()
  @IsBoolean()
  SMOKER: boolean;

  @ApiProperty({ example: 1 })
  @IsInt()
  @Min(1)
  @Max(70)
  @ValidateIf((o: CreateProfileSurveyDto) => o.SMOKER === true)
  SMOKER_DURATION: number;

  @ApiProperty({ example: 1 })
  @IsInt()
  @Min(1)
  @Max(80)
  @ValidateIf((o: CreateProfileSurveyDto) => o.SMOKER === true)
  SMOKER_DAILY_QTY: number;

  @ApiProperty()
  @IsBoolean()
  SMOKER_CANNABIS: boolean;

  @ApiProperty({ example: 1 })
  @IsInt()
  @Min(1)
  @Max(80)
  @ValidateIf((o: CreateProfileSurveyDto) => o.SMOKER_CANNABIS === true)
  SMOKER_CANNABIS_DAILY_QTY: number;

  @ApiProperty()
  @IsBoolean()
  ALCOHOL: boolean;

  @ApiProperty({ example: 1 })
  @IsInt()
  @Min(1)
  @Max(100)
  @ValidateIf((o: CreateProfileSurveyDto) => o.ALCOHOL === true)
  ALCOHOL_WEEKLY_QTY: number;

  @ApiProperty()
  @IsBoolean()
  CONSUMPTION_TEA: boolean;

  @ApiProperty({ example: 1 })
  @IsInt()
  @Min(1)
  @Max(50)
  @ValidateIf((o: CreateProfileSurveyDto) => o.CONSUMPTION_TEA === true)
  CONSUMPTION_TEA_DAILY_QTY: number;
}

/**
 * Representation of a profile survey
 */
export class ProfileSurveyDto extends OmitType(CreateProfileSurveyDto, ['patient'] as const) {
  @ApiProperty({
    type: 'string',
    format: 'uuid',
  })
  id: string;

  @ApiProperty({ description: 'Body Mass Index' })
  BMI: number;

  @ApiProperty({
    format: 'date-time',
  })
  createdDate: string;

  @ApiProperty({
    format: 'date-time',
  })
  updatedDate: string;
}

/**
 * Payload to create a Follow Up Profile Survey
 */
export class CreateFollowUpProfileSurveyDto extends OmitType(CreateProfileSurveyDto, [
  'BIRTHDATE',
  'DEPARTEMENT_OF_RESIDENCE',
  'PROFESSIONAL_ACTIVITY',
  'PROFESSIONAL_ACTIVITY_HOME_OFFICE',
  'PROFESSIONAL_ACTIVITY_BUSINESS_TRIPS',
  'PROFESSIONAL_ACTIVITY_STAGGERED_HOURS',
  'PROFESSIONAL_ACTIVITY_SEDENTARY',
  'PROFESSIONAL_ACTIVITY_ON_FOOT',
  'PROFESSIONAL_ACTIVITY_PHYSICAL',
  'FOOD_SUPPLEMENT',
  'FOOD_SUPPLEMENT_LABEL',
  'COACHING_GOALS_WEIGHT_LOSS',
  'COACHING_GOALS_IMPROVE_DIET',
  'COACHING_GOALS_BLOOD_TEST',
  'COACHING_GOALS_LIFESTYLE',
  'COACHING_GOALS_ELSE',
  'HEIGHT',
  'CHILDHOOD_OVERWEIGHT',
  'WEIGHT_MAXIMUM',
  'WEIGHT_MINIMUM',
  'SMOKER',
  'SMOKER_DURATION',
  'SMOKER_CANNABIS',
  'ALCOHOL',
  'CONSUMPTION_TEA',
] as const) {}

/**
 * Payload to update a Sign Up Profile Survey
 */
export class UpdateProfileSurveyDto extends OmitType(CreateProfileSurveyDto, ['patient'] as const) {}

/**
 * Returns the ISO Date string of
 * now - 18 years
 */
function nowMinus18Years() {
  const minBirthday = new Date();

  minBirthday.setFullYear(minBirthday.getFullYear() - 18);
  return minBirthday.toISOString();
}
