import {
  ApiProperty,
  IsArray,
  IsBoolean,
  IsEnum,
  IsNumber,
  IsString,
  IsUUID,
  OmitType,
  Type,
  ValidateIf,
  ValidateNested,
} from './decorators-nest';

export enum SpecificDiet {
  VEGETARIAN = 'vegetarian',
  PESCO_VEGETARIAN = 'pesco-vegetarian',
  WITHOUT_PORK = 'without-pork',
  FALSE = 'false',
}

export enum MealFrequency {
  WEEK = 'week',
  WEEKEND = 'weekend',
  ALWAYS = 'always',
  NO = 'no',
}

export enum MealComposition {
  MAIN_COURSE = 'main-course',
  STARTER = 'starter',
  DESSERT = 'dessert',
  ALL = 'all',
}

/* Users can have aversion towards a single ingredients or a whole ingredient category */
export enum AversionType {
  INGREDIENT = 'INGREDIENT',
  TAG = 'TAG',
}

/** Payload to exclude ingredient */
export class AversionDto {
  @IsNumber()
  @ApiProperty({ description: 'Relation id (Ingredient or Tag)', example: 42 })
  id: number;

  @IsString()
  @ApiProperty({ description: 'Relation name (Ingredient or Tag)', example: 'Coco Pops' })
  name: string;

  @IsEnum(AversionType)
  @ApiProperty({
    required: false,
    enum: AversionType,
    default: AversionType.INGREDIENT,
    description: 'Type of aversion. If not provided, INGREDIENT will be used - for backward compatibility.',
  })
  type = AversionType.INGREDIENT;
}

/**
 * Payload to create an Food Preference Survey
 */
export class CreateFoodPreferenceSurveyDto {
  @ApiProperty({
    description: 'related patient',
    format: 'uuid',
  })
  @IsUUID()
  patient: string;

  @ApiProperty({
    required: true,
    enum: SpecificDiet,
    description: 'Régime alimentaire spécifique',
  })
  @IsEnum(SpecificDiet)
  SPECIFIC_DIET: SpecificDiet;

  @ApiProperty({ description: 'Aliments à éviter', isArray: true, type: AversionDto })
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => AversionDto)
  FOOD_EXCLUSION: AversionDto[];

  @ApiProperty({
    required: true,
    enum: MealFrequency,
    description: 'Petit-déjeuner',
  })
  @IsEnum(MealFrequency)
  BREAKFAST: MealFrequency;

  @ApiProperty({ description: 'Prête à prendre un petit-déjeuner en semaine' })
  @IsBoolean()
  @ValidateIf(
    (o: CreateFoodPreferenceSurveyDto) => o.BREAKFAST !== MealFrequency.ALWAYS && o.BREAKFAST !== MealFrequency.WEEK
  )
  BREAKFAST_WEEK_CHANGE: boolean;

  @ApiProperty({
    required: true,
    enum: MealFrequency,
    description: 'Collation le matin',
  })
  @IsEnum(MealFrequency)
  MORNING_SNACK: MealFrequency;

  @ApiProperty({
    required: true,
    enum: MealComposition,
    description: 'Composition du déjeuner en semaine',
  })
  @IsEnum(MealComposition)
  LUNCH_WEEK_COMPOSITION: MealComposition;

  @ApiProperty({
    required: true,
    enum: MealComposition,
    description: 'Composition du déjeuner le week-end',
  })
  @IsEnum(MealComposition)
  LUNCH_WEEKEND_COMPOSITION: MealComposition;

  @ApiProperty({
    required: true,
    enum: MealFrequency,
    description: 'Collation l’après-midi',
  })
  @IsEnum(MealFrequency)
  AFTERNOON_SNACK: MealFrequency;

  @ApiProperty({
    required: true,
    enum: MealComposition,
    description: 'Composition de votre dîner en semaine',
  })
  @IsEnum(MealComposition)
  DINER_WEEK_COMPOSITION: MealComposition;

  @ApiProperty({
    required: true,
    enum: MealComposition,
    description: 'Composition du dîner le week-end',
  })
  @IsEnum(MealComposition)
  DINER_WEEKEND_COMPOSITION: MealComposition;

  @ApiProperty({
    description: 'Temps à consacrer à la préparation des repas la semaine (en min)',
    example: 30,
    minimum: 15,
    maximum: 240,
    exclusiveMinimum: false,
    exclusiveMaximum: false,
  })
  @IsNumber()
  MEAL_PREPARATION_TIME_WEEK: number;

  @ApiProperty({
    description: 'Temps à consacrer à la préparation des repas le week-end (en min)',
    example: 30,
    minimum: 15,
    maximum: 240,
    exclusiveMinimum: false,
    exclusiveMaximum: false,
  })
  @IsNumber()
  MEAL_PREPARATION_TIME_WEEKEND: number;
}

/**
 * Representation of a food preference survey
 */
export class FoodPreferenceSurveyDto extends OmitType(CreateFoodPreferenceSurveyDto, ['patient'] as const) {
  @ApiProperty({
    type: 'string',
    format: 'uuid',
  })
  id: string;

  @ApiProperty({
    format: 'date-time',
  })
  createdDate: string;

  @ApiProperty({
    format: 'date-time',
  })
  updatedDate: string;
}
