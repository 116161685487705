import { ApiProperty } from './decorators-nest';

export class ShoppingListDTO {
  @ApiProperty({
    description: 'Quantity of ingredient',
    format: 'integer',
    example: 42,
  })
  quantity: number;

  @ApiProperty({
    description: "Ingredient's unit identifier",
    format: 'integer',
    example: 666,
  })
  unit_id: number;

  @ApiProperty({
    description: "Ingredient's unit name",
    example: 'cuillère à soupe',
  })
  unit_name: string;

  @ApiProperty({
    description: 'Ingredient identifier',
    format: 'integer',
    example: 13,
  })
  ingredient_id: number;

  @ApiProperty({
    description: 'Ingredient name',
    example: "Huile d'olive",
  })
  ingredient_name: string;

  @ApiProperty({
    description: 'Ingredient image url',
    format: 'uri',
    example: 'https://static.youmiam.com/images/ingredient/100x100/basilic.jpg',
  })
  ingredient_img_url: string;

  @ApiProperty({
    description: "Ingredient's supermarket aisle identifier",
    format: 'integer',
    example: 7,
  })
  supermarket_aisle_id: number;

  @ApiProperty({
    description: "Ingredient's supermarket aisle name",
    example: 'Epicerie salée',
  })
  supermarket_aisle_name: string;
}
