import { ApiProperty, IsBoolean, IsEnum, IsUUID, OmitType, ValidateIf } from './decorators-nest';

export enum MemorisingDifficultiesEvents {
  RECENT = 'recent',
  OLD = 'old',
  ALL = 'all',
  NONE = 'none',
}

/**
 * Payload to create a Memory Survey
 * @todo: translate each description in english
 */
export class CreateMemorySurveyDto {
  @ApiProperty({
    description: 'related patient',
    format: 'uuid',
  })
  @IsUUID()
  patient: string;

  @ApiProperty({
    required: true,
    description: 'Mémoire moins performante',
  })
  @IsBoolean()
  MEMORY_LESS_EFFICIENT: boolean;

  @ApiProperty({
    required: false,
    description: 'Difficultés à mémoriser. Required if MEMORY_LESS_EFFICIENT is true',
  })
  @IsBoolean()
  @ValidateIf((o: CreateMemorySurveyDto) => o.MEMORY_LESS_EFFICIENT === true)
  MEMORISING_DIFFICULTIES: boolean;

  @ApiProperty({
    required: false,
    enum: MemorisingDifficultiesEvents,
    description: 'Difficultés à se souvenir des événements. Required if MEMORY_LESS_EFFICIENT is true',
  })
  @IsEnum(MemorisingDifficultiesEvents)
  @ValidateIf((o: CreateMemorySurveyDto) => o.MEMORY_LESS_EFFICIENT === true)
  MEMORISING_DIFFICULTIES_EVENTS: MemorisingDifficultiesEvents;

  @ApiProperty({
    required: false,
    description: "Difficultés à se souvenir de la date d'aujourd'hui. Required if MEMORY_LESS_EFFICIENT is true",
  })
  @IsBoolean()
  @ValidateIf((o: CreateMemorySurveyDto) => o.MEMORY_LESS_EFFICIENT === true)
  MEMORY_DATE: boolean;

  @ApiProperty({
    required: false,
    description:
      'Difficultés à assimilier les informations à très court terme. Required if MEMORY_LESS_EFFICIENT is true',
  })
  @IsBoolean()
  @ValidateIf((o: CreateMemorySurveyDto) => o.MEMORY_LESS_EFFICIENT === true)
  MEMORY_SHORT_TERM_DIFFICULTIES: boolean;

  @ApiProperty({
    required: true,
    description: 'Difficultés à prendre des décisions. Required if MEMORY_LESS_EFFICIENT is true',
  })
  @IsBoolean()
  DECISION_DIFFICULTIES: boolean;

  @ApiProperty({
    required: true,
    description: 'Difficultés de concentration',
  })
  @IsBoolean()
  CONCENTRATION_DIFFICULTIES: boolean;

  @ApiProperty({
    required: true,
    description: 'Performances intellectuelles amoindries',
  })
  @IsBoolean()
  INTELLECTUAL_PERFORMANCE_DECREASED: boolean;
}

/**
 * Representation of a memory survey
 */
export class MemorySurveyDto extends OmitType(CreateMemorySurveyDto, ['patient'] as const) {
  @ApiProperty({
    type: 'string',
    format: 'uuid',
  })
  id: string;

  @ApiProperty({
    format: 'date-time',
  })
  createdDate: string;

  @ApiProperty({
    format: 'date-time',
  })
  updatedDate: string;
}

/**
 * Payload to update a sign up memory survey
 */
export class UpdateMemorySurveyDto extends OmitType(CreateMemorySurveyDto, ['patient'] as const) {}
