import { ApiProperty, IsBoolean, IsEnum, IsUUID, OmitType } from './decorators-nest';

export enum ResidenceEnvironment {
  URBAN = 'urban',
  RURAL = 'rural',
}

/**
 * Payload to create an Oxidative Survey
 * @todo: translate each description in english
 */
export class CreateOxidativeSurveyDto {
  @ApiProperty({
    description: 'related patient',
    format: 'uuid',
  })
  @IsUUID()
  patient: string;

  @ApiProperty({
    required: true,
    enum: ResidenceEnvironment,
  })
  @IsEnum(ResidenceEnvironment)
  RESIDENCE_ENVIRONMENT: ResidenceEnvironment;

  @ApiProperty({
    required: true,
  })
  @IsBoolean()
  ENVIRONMENT_POLLUTED: boolean;

  @ApiProperty({
    required: true,
  })
  @IsBoolean()
  HIGH_SUN_EXPOSURE: boolean;

  @ApiProperty({
    required: true,
  })
  @IsBoolean()
  SUFFICIENT_SUN_EXPOSURE: boolean;

  @ApiProperty({
    required: true,
  })
  @IsBoolean()
  SPOTTED_SKIN: boolean;

  @ApiProperty({
    required: true,
  })
  @IsBoolean()
  CICATRISING_DIFFICULTIES: boolean;

  @ApiProperty({
    required: true,
  })
  @IsBoolean()
  REPEATED_INFECTIONS: boolean;

  @ApiProperty({
    required: true,
  })
  @IsBoolean()
  MUSCLE_OR_JOINT_PAIN: boolean;

  @ApiProperty({
    required: true,
  })
  @IsBoolean()
  BLOOD_CIRCULATION_ISSUES: boolean;

  @ApiProperty({
    required: true,
  })
  @IsBoolean()
  HAIR_DULL_BRITTLE: boolean;
}

/**
 * Representation of a mood survey
 */
export class OxidativeSurveyDto extends OmitType(CreateOxidativeSurveyDto, ['patient'] as const) {
  @ApiProperty({
    type: 'string',
    format: 'uuid',
  })
  id: string;

  @ApiProperty({
    format: 'date-time',
  })
  createdDate: string;

  @ApiProperty({
    format: 'date-time',
  })
  updatedDate: string;
}

/**
 * Payload to update a Sign Up Oxidative Survey
 */
export class UpdateOxidativeSurveyDto extends OmitType(CreateOxidativeSurveyDto, ['patient'] as const) {}
