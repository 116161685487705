import { ApiProperty, IsBoolean, IsNumber, IsUUID, Max, Min, OmitType, ValidateIf } from './decorators-nest';

/**
 * Payload to create a Physical Activity Survey
 * @todo: translate each description in english
 */
export class CreatePhysicalActivitySurveyDto {
  @ApiProperty({
    description: 'related patient',
    format: 'uuid',
  })
  @IsUUID()
  patient: string;

  @ApiProperty({
    required: true,
    description: 'Time spent laying (daily, in hours)',
    example: 1,
  })
  @IsNumber()
  @Min(1)
  @Max(18)
  PAL_TIME_LAYING: number;

  @ApiProperty({
    required: true,
    description: 'Time spent sitting (daily, in hours)',
  })
  @IsNumber()
  @Min(0)
  @Max(18)
  PAL_TIME_SITTING: number;

  @ApiProperty({
    required: true,
    description: 'Time spent standing (daily, in hours)',
  })
  @IsNumber()
  @Min(0)
  @Max(18)
  PAL_TIME_STANDING: number;

  @ApiProperty({
    required: true,
    description: 'Time spent walking or cycling (daily, in hours)',
  })
  @IsNumber()
  @Min(0)
  @Max(18)
  PAL_WALKING_OR_CYCLING: number;

  @ApiProperty({
    required: true,
    description: 'Job requires medium physical activity ?',
  })
  @IsBoolean()
  PAL_PROFESSIONAL_MEDIUM_INTENSITY: boolean;

  @ApiProperty({
    required: false,
    description:
      'Time spent doing medium physical activity during work (if any, daily, in hours). Required if PAL_PROFESSIONAL_MEDIUM_INTENSITY',
    example: 0.25,
  })
  @IsNumber()
  @Min(0.25)
  @Max(18)
  @ValidateIf((o: CreatePhysicalActivitySurveyDto) => o.PAL_PROFESSIONAL_MEDIUM_INTENSITY === true)
  PAL_PROFESSIONAL_MEDIUM_INTENSITY_DURATION: number;

  @ApiProperty({
    required: true,
    description: 'Housework requires medium physical activity ?',
  })
  @IsBoolean()
  PAL_HOUSEWORK: boolean;

  @ApiProperty({
    required: false,
    description: 'Time spent doing medium physical activity during housework (if any, daily, in hours)',
    example: 0.25,
  })
  @IsNumber()
  @Min(0.25)
  @Max(18)
  @ValidateIf((o: CreatePhysicalActivitySurveyDto) => o.PAL_HOUSEWORK === true)
  PAL_HOUSEWORK_DURATION: number;

  @ApiProperty({
    required: true,
    description: 'Practice of low intensity sport ?',
  })
  @IsBoolean()
  PAL_SPORT_INTENSITY_LOW: boolean;

  @ApiProperty({
    required: false,
    description: 'Time spent practicing low intensity sports (if any, weekly, in hours)',
    example: 1,
  })
  @IsNumber()
  @Min(1)
  @Max(35)
  @ValidateIf((o: CreatePhysicalActivitySurveyDto) => o.PAL_SPORT_INTENSITY_LOW === true)
  PAL_SPORT_INTENSITY_LOW_DURATION_WEEKLY: number;

  @ApiProperty({
    required: true,
    description: 'Practice of medium intensity sport ?',
  })
  @IsBoolean()
  PAL_SPORT_INTENSITY_MODERATE: boolean;

  @ApiProperty({
    required: false,
    description: 'Time spent practicing high intensity sports (if any, weekly, in hours)',
    example: 1,
  })
  @IsNumber()
  @Min(1)
  @Max(35)
  @ValidateIf((o: CreatePhysicalActivitySurveyDto) => o.PAL_SPORT_INTENSITY_MODERATE === true)
  PAL_SPORT_INTENSITY_MODERATE_DURATION_WEEKLY: number;

  @ApiProperty({
    required: true,
    description: 'Practice of high intensity sport ?',
  })
  @IsBoolean()
  PAL_SPORT_INTENSITY_HIGH: boolean;

  @ApiProperty({
    required: false,
    description: 'Time spent practicing high intensity sports (if any, weekly, in hours)',
    example: 1,
  })
  @IsNumber()
  @Min(1)
  @Max(35)
  @ValidateIf((o: CreatePhysicalActivitySurveyDto) => o.PAL_SPORT_INTENSITY_HIGH === true)
  PAL_SPORT_INTENSITY_HIGH_DURATION_WEEKLY: number;
}

/**
 * Representation of a PhysicalActivity survey
 */
export class PhysicalActivitySurveyDto extends OmitType(CreatePhysicalActivitySurveyDto, ['patient'] as const) {
  @ApiProperty({
    type: 'string',
    format: 'uuid',
  })
  id: string;

  @ApiProperty({ description: 'Physical Activity Level' })
  PAL: number;

  @ApiProperty({
    format: 'date-time',
  })
  createdDate: string;

  @ApiProperty({
    format: 'date-time',
  })
  updatedDate: string;
}

/**
 * Payload to create a Follow Up Physical Activity Survey
 */
export class CreateFollowUpPhysicalActivitySurveyDto extends CreatePhysicalActivitySurveyDto {
  @ApiProperty({
    required: true,
    description: 'Change of physical activity level during past week ?',
  })
  @IsBoolean()
  PAL_CHANGE: boolean;

  @ApiProperty({
    description: 'Will you maintain this new physical activity level ?',
    required: true,
  })
  @IsBoolean()
  @ValidateIf((o: CreateFollowUpPhysicalActivitySurveyDto) => o.PAL_CHANGE === true)
  PAL_CHANGE_LASTING: boolean;

  @ApiProperty({
    description: 'Time spent laying (daily, in hours). Required if PAL_CHANGE is true.',
    required: false,
  })
  @ValidateIf((o: CreateFollowUpPhysicalActivitySurveyDto) => o.PAL_CHANGE === true)
  PAL_TIME_LAYING: number;

  @ApiProperty({
    description: 'Time spent sitting (daily, in hours). Required if PAL_CHANGE is true.',
    required: false,
  })
  @ValidateIf((o: CreateFollowUpPhysicalActivitySurveyDto) => o.PAL_CHANGE === true)
  PAL_TIME_SITTING: number;

  @ApiProperty({
    description: 'Time spent standing (daily, in hours). Required if PAL_CHANGE is true.',
    required: false,
  })
  @ValidateIf((o: CreateFollowUpPhysicalActivitySurveyDto) => o.PAL_CHANGE === true)
  PAL_TIME_STANDING: number;

  @ApiProperty({
    description: 'Time spent walking or cycling (daily, in hours). Required if PAL_CHANGE is true.',
    required: false,
  })
  @ValidateIf((o: CreateFollowUpPhysicalActivitySurveyDto) => o.PAL_CHANGE === true)
  PAL_WALKING_OR_CYCLING: number;

  @ApiProperty({
    description: 'The job requires medium physical activity. Required if PAL_CHANGE is true.',
    required: false,
  })
  @ValidateIf((o: CreateFollowUpPhysicalActivitySurveyDto) => o.PAL_CHANGE === true)
  PAL_PROFESSIONAL_MEDIUM_INTENSITY: boolean;

  @ValidateIf((o: CreateFollowUpPhysicalActivitySurveyDto) => o.PAL_CHANGE === true)
  PAL_PROFESSIONAL_MEDIUM_INTENSITY_DURATION: number;

  @ApiProperty({
    description: 'Housework requires medium physical activity. Required if PAL_CHANGE is true.',
    required: false,
  })
  @ValidateIf((o: CreateFollowUpPhysicalActivitySurveyDto) => o.PAL_CHANGE === true)
  PAL_HOUSEWORK: boolean;

  @ValidateIf((o: CreateFollowUpPhysicalActivitySurveyDto) => o.PAL_CHANGE === true)
  PAL_HOUSEWORK_DURATION: number;

  @ApiProperty({
    description: 'Practice of low intensity sport. Required if PAL_CHANGE is true.',
    required: false,
  })
  @ValidateIf((o: CreateFollowUpPhysicalActivitySurveyDto) => o.PAL_CHANGE === true)
  PAL_SPORT_INTENSITY_LOW: boolean;

  @ValidateIf((o: CreateFollowUpPhysicalActivitySurveyDto) => o.PAL_CHANGE === true)
  PAL_SPORT_INTENSITY_LOW_DURATION_WEEKLY: number;

  @ApiProperty({
    description: 'Practice of medium intensity sport. Required if PAL_CHANGE is true.',
    required: false,
  })
  @ValidateIf((o: CreateFollowUpPhysicalActivitySurveyDto) => o.PAL_CHANGE === true)
  PAL_SPORT_INTENSITY_MODERATE: boolean;

  @ValidateIf((o: CreateFollowUpPhysicalActivitySurveyDto) => o.PAL_CHANGE === true)
  PAL_SPORT_INTENSITY_MODERATE_DURATION_WEEKLY: number;

  @ApiProperty({
    description: 'Practice of high intensity sport. Required if PAL_CHANGE is true.',
    required: false,
  })
  @ValidateIf((o: CreateFollowUpPhysicalActivitySurveyDto) => o.PAL_CHANGE === true)
  PAL_SPORT_INTENSITY_HIGH: boolean;

  @ValidateIf((o: CreateFollowUpPhysicalActivitySurveyDto) => o.PAL_CHANGE === true)
  PAL_SPORT_INTENSITY_HIGH_DURATION_WEEKLY: number;
}

/**
 * Payload to update a Sign Up Physical Activity Survey
 */
export class UpdatePhysicalActivitySurveyDto extends OmitType(CreatePhysicalActivitySurveyDto, ['patient'] as const) {}
