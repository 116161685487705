import { IsEnum, isBoolean } from 'class-validator';
import { ApiProperty, IsNotEmpty, IsString, PartialType, IsUrl, IsBoolean } from './decorators-nest';

/** Enum for Categories */
export enum ToolsheetsCategory {
  ALIMENTATION = 'Alimentation',
  MODE_DE_VIE = 'Mode de vie',
  FERTILITE = 'Fertilité',
  AUTRE = 'Autre',
}

export class UpdateAccessToolsheetsDto {
  @ApiProperty({ example: 'false' })
  @IsBoolean()
  @IsNotEmpty()
  hasAccess: boolean;
}

/** Properties that are shared between all kind of DTOs, the ToolsheetsDto, CreationToolsheetsDto, UpdateToolsheetsDto... */
class SharedToolsheetsDto {
  @ApiProperty({ example: 'Perturbateurs endocriniens' })
  @IsString()
  @IsNotEmpty()
  title: string;

  @ApiProperty({
    example: 'https://www.notion.so/fablife/Perturbateurs-endocriniens-00e31aff2266436bbb52c8f3a4244f15?pvs=4',
  })
  @IsUrl()
  @IsNotEmpty()
  link: string;

  @ApiProperty({
    enum: ToolsheetsCategory,
    example: ToolsheetsCategory.MODE_DE_VIE,
  })
  @IsEnum(ToolsheetsCategory)
  category: ToolsheetsCategory;
}

/** Toolsheets interface */
export class ToolsheetsDto extends SharedToolsheetsDto {
  @ApiProperty({ example: '124ed8db-25e5-47d7-a4f3-b1d9fa934650' })
  id: string;

  @ApiProperty({ example: '2021-07-20T11:33:58.867Z' })
  createdDate: Date;

  @ApiProperty({ example: '2021-07-20T11:33:58.867Z' })
  updatedDate: Date;
}

export class PatientToolsheetsDto {
  @ApiProperty({ example: 'false' })
  @IsBoolean()
  @IsNotEmpty()
  hasAccess: boolean;

  @ApiProperty({ type: ToolsheetsDto })
  toolsheets: ToolsheetsDto;
}

/** Toolsheet creation payload */
export class CreateToolsheetsDto extends SharedToolsheetsDto {}

/** Toolsheet update payload */
export class UpdateToolsheetsDto extends PartialType(CreateToolsheetsDto) {}
