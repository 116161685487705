import {
  ApiProperty,
  ApiPropertyOptional,
  IsBoolean,
  IsDate,
  IsDefined,
  IsEnum,
  IsNumber,
  IsOptional,
  IsString,
  Min,
  OmitType,
  PickType,
} from './decorators-nest';
import { SubRecipeDto, SubRecipeImageDto } from './recipe.interface';

/** Available choices for the breakfast  */
export enum BreakfastOption {
  OPTION_1 = 'OPTION_1',
  OPTION_2 = 'OPTION_2',
  OTHER = 'OTHER',
}

/** Available choices for the lunch or dinner  */
export enum LunchDinnerOption {
  REGULAR = 'REGULAR',
  MINUTE = 'MINUTE',
  OUTSIDE = 'OUTSIDE',
  OTHER = 'OTHER',
}

export enum MealToSwitch {
  Breakfast1 = 'Breakfast1',
  Breakfast2 = 'Breakfast2',
  MorningSnack = 'MorningSnack',
  LunchAppetizer = 'LunchAppetizer',
  LunchDish = 'LunchDish',
  LunchDessert = 'LunchDessert',
  LunchMinute = 'LunchMinute',
  AfternoonSnack = 'AfternoonSnack',
  DinerAppetizer = 'DinerAppetizer',
  DinerDish = 'DinerDish',
  DinerDessert = 'DinerDessert',
  DinerMinute = 'DinerMinute',
}

export class DailyMealPlanningDto {
  @IsNumber()
  @ApiProperty({
    description: 'Id of meal planning',
  })
  id: number;

  @IsString()
  @ApiProperty({
    description: 'Id of meal planning target patient',
  })
  patientId: string;

  @IsDate()
  @ApiProperty({
    description: 'Meal planning date',
  })
  date: Date;

  @IsDefined()
  @ApiProperty({ description: 'Recipe for first breakfast option' })
  breakfastOption1: SubRecipeImageDto;

  @IsDefined()
  @ApiProperty({ description: 'Recipe for second breakfast option' })
  breakfastOption2: SubRecipeImageDto;

  @IsDefined()
  @ApiProperty({ description: 'Recipe for morning snack' })
  morningSnack: SubRecipeImageDto;

  @IsDefined()
  @ApiProperty({ description: 'Recipe for lunch appetizer' })
  lunchAppetizer: SubRecipeImageDto;

  @IsDefined()
  @ApiProperty({ description: 'Recipe for lunch dish' })
  lunchDish: SubRecipeImageDto;

  @IsDefined()
  @ApiProperty({ description: 'Recipe for lunch dessert' })
  lunchDessert: SubRecipeImageDto;

  @IsDefined()
  @ApiProperty({ description: 'Recipe for lunch minute' })
  lunchMinute: SubRecipeImageDto;

  @IsDefined()
  @ApiProperty({ description: 'Recipe for afternoon snack' })
  afternoonSnack: SubRecipeImageDto;

  @IsDefined()
  @ApiProperty({ description: 'Recipe for dinner appetizer' })
  dinerAppetizer: SubRecipeImageDto;

  @IsDefined()
  @ApiProperty({ description: 'Recipe for dinner dish' })
  dinerDish: SubRecipeImageDto;

  @IsDefined()
  @ApiProperty({ description: 'Recipe for dinner dessert' })
  dinerDessert: SubRecipeImageDto;

  @IsDefined()
  @ApiProperty({ description: 'Recipe for dinner minute' })
  dinerMinute: SubRecipeImageDto;

  @IsEnum(BreakfastOption)
  @IsOptional()
  @ApiPropertyOptional({
    enum: BreakfastOption,
    description:
      'Patient is offered two breakfast options by meal planning api. This field allows to record which option was chosen by patient',
  })
  selectedBreakfast: BreakfastOption;

  @IsBoolean()
  @IsOptional()
  @ApiPropertyOptional({
    description:
      'According to its food preferences, patient is offered a snack option. This field allows to record whether patient has eaten the snack',
  })
  tookMorningSnack: boolean;

  @IsEnum(LunchDinnerOption)
  @IsOptional()
  @ApiPropertyOptional({
    enum: LunchDinnerOption,
    description:
      'Patient is offered three lunch options by meal planning api. This field allows to record which option was chosen by patient',
  })
  selectedLunch: LunchDinnerOption;

  @IsBoolean()
  @IsOptional()
  @ApiPropertyOptional({
    description:
      'According to its food preferences, patient is offered a snack option. This field allows to record whether patient has eaten the snack',
  })
  tookAfternoonSnack?: boolean;

  @IsEnum(LunchDinnerOption)
  @IsOptional()
  @ApiPropertyOptional({
    enum: LunchDinnerOption,
    description:
      'Patient is offered three dinner options by meal planning api. This field allows to record which option was chosen by patient',
  })
  selectedDiner: LunchDinnerOption;

  @IsBoolean()
  @IsOptional()
  @ApiPropertyOptional({
    description:
      'According to its membership, patient has to take supplements. This field allows to record whether patient has taken prescribed supplements',
  })
  tookSupplements: boolean;

  @IsDefined()
  @ApiProperty({
    description: 'Recipes for breakfast',
    isArray: true,
    type: SubRecipeDto,
  })
  otherBreakfasts: SubRecipeDto[];

  @IsDefined()
  @ApiProperty({
    description: 'Recipes for morning snack',
    isArray: true,
    type: SubRecipeDto,
  })
  otherMorningSnacks: SubRecipeDto[];

  @IsDefined()
  @ApiProperty({
    description: 'Recipes for lunch appetizer',
    isArray: true,
    type: SubRecipeDto,
  })
  otherLunchAppetizers: SubRecipeDto[];

  @IsDefined()
  @ApiProperty({
    description: 'Recipes for lunch dish',
    isArray: true,
    type: SubRecipeDto,
  })
  otherLunchDishes: SubRecipeDto[];

  @IsDefined()
  @ApiProperty({
    description: 'Recipes for lunch dessert',
    isArray: true,
    type: SubRecipeDto,
  })
  otherLunchDesserts: SubRecipeDto[];

  @IsDefined()
  @ApiProperty({
    description: 'Recipes for lunch minute',
    isArray: true,
    type: SubRecipeDto,
  })
  otherLunchMinutes: SubRecipeDto[];

  @IsDefined()
  @ApiProperty({
    description: 'Recipes for afternoon snack',
    isArray: true,
    type: SubRecipeDto,
  })
  otherAfternoonSnacks: SubRecipeDto[];

  @IsDefined()
  @ApiProperty({
    description: 'Recipes for dinner appetizer',
    isArray: true,
    type: SubRecipeDto,
  })
  otherDinerAppetizers: SubRecipeDto[];

  @IsDefined()
  @ApiProperty({
    description: 'Recipes for dinner dish',
    isArray: true,
    type: SubRecipeDto,
  })
  otherDinerDishes: SubRecipeDto[];

  @IsDefined()
  @ApiProperty({
    description: 'Recipes for dinner dessert',
    isArray: true,
    type: SubRecipeDto,
  })
  otherDinerDesserts: SubRecipeDto[];

  @IsDefined()
  @ApiProperty({
    description: 'Recipes for dinner minute',
    isArray: true,
    type: SubRecipeDto,
  })
  otherDinerMinutes: SubRecipeDto[];
}

export class CreateDailyMealPlanningDto extends OmitType(DailyMealPlanningDto, [
  'id',
  'selectedBreakfast',
  'tookMorningSnack',
  'selectedLunch',
  'selectedDiner',
  'tookAfternoonSnack',
  'tookSupplements',
]) {}

export class UpdateMealPlanningDto extends PickType(DailyMealPlanningDto, [
  'selectedBreakfast',
  'tookMorningSnack',
  'selectedLunch',
  'selectedDiner',
  'tookAfternoonSnack',
  'tookSupplements',
]) {}

export class GenerateDailyMealPlanningsManuallyDto {
  @IsString()
  @ApiProperty({
    description: 'Id of meal planning target patient',
    type: 'string',
    example: 'd2b34b79-49ae-4c0c-9f70-4fe26bb21462',
  })
  patientId: string;

  @IsString()
  @ApiProperty({
    description: 'Meal planning date',
    type: 'string',
    example: '2020-10-17',
  })
  date: Date;

  @IsNumber()
  @ApiProperty({
    description: 'Number of days to generate',
    example: 2,
    minimum: 1,
  })
  @Min(1)
  numberOfDays: number;
}
