import { ApiProperty, IsArray, IsNumber, IsString, Type, ValidateNested } from './decorators-nest';

export class CreateFoodNameDto {
  @ApiProperty({
    description: 'Sequential number generated by the database identifying the food records. This is the primary key',
    required: false,
  })
  @IsString()
  public FoodID?: string;

  @ApiProperty({
    description: 'Identifier code for the foods. In the 2007b version this was the FD_C',
    required: false,
  })
  @IsString()
  public FoodCode?: string;

  @ApiProperty({
    description: 'Sequential number generated by the database for the food groups',
    required: false,
  })
  @IsString()
  public FoodGroupID?: string;

  @ApiProperty({
    description: 'Sequential number generated by the database for the food sources.',
    required: false,
  })
  @IsString()
  public FoodSourceID?: string;

  @ApiProperty({
    description: 'Complete food name in English',
    required: false,
  })
  @IsString()
  public FoodDescription?: string;

  @ApiProperty({
    description: 'Complete food name in French',
    required: false,
  })
  @IsString()
  public FoodDescriptionF?: string;

  @ApiProperty({
    description: 'Date the food name data was entered into the database',
    required: false,
  })
  @IsString()
  public FoodDateOfEntry?: string;

  @ApiProperty({
    description: 'Date the food name data was originally published',
    required: false,
  })
  @IsString()
  public FoodDateOfPublication?: string;

  @ApiProperty({
    description: 'Corresponds to the USDA NDB N/A code',
    required: false,
  })
  @IsString()
  public CountryCode?: string;

  @ApiProperty({
    description: 'Scientific name of the food',
    required: false,
  })
  @IsString()
  public ScientificName?: string;
}

export class FoodNameDto extends CreateFoodNameDto {
  @ApiProperty({
    type: 'number',
  })
  id?: number;
}

export class CreateNutrientAmountDto {
  @ApiProperty({
    description: 'Sequential number generated by the database identifying the food records.',
    required: false,
  })
  @IsString()
  public FoodID?: string;

  @ApiProperty({
    description: 'Sequential number generated by the database for the nutrient names',
    required: false,
  })
  @IsString()
  public NutrientID?: string;

  @ApiProperty({
    description: 'Sequential number generated by the database for the nutrient sources',
    required: false,
  })
  @IsString()
  public NutrientSourceID?: string;

  @ApiProperty({
    description:
      'Mean value in 100g, edible portion. (The number of decimal places does not reflect the accuracy of the data.).',
    required: false,
  })
  @IsString()
  public NutrientValue?: string;

  @ApiProperty({
    description: 'Standard error of the mean',
    required: false,
  })
  @IsString()
  public StandardError?: string;

  @ApiProperty({
    description: 'Number of samples',
    required: false,
  })
  @IsString()
  public NumberofObservations?: string;

  @ApiProperty({
    description: 'Date the data was entered into the database',
    required: false,
  })
  @IsString()
  public NutrientDateOfEntry?: string;
}

export class CreateConversionFactorDto {
  @ApiProperty({
    description: 'Sequential number generated by the database identifying the food records.',
    required: false,
  })
  @IsString()
  public FoodID?: string;

  @ApiProperty({
    description: 'Sequential number generated by the database for the measure descriptions',
    required: false,
  })
  @IsString()
  public MeasureID?: string;

  @ApiProperty({
    description:
      'The factor by which one would multiply the nutrient per 100g to obtain nutrient amounts per the measure described (the weight of that food in the measure described divided by 100)',
    required: false,
  })
  @IsString()
  public ConversionFactorValue?: string;

  @ApiProperty({
    description: 'Date the data was entered into the database',
    required: false,
  })
  @IsString()
  public ConvFactorDateOfEntry?: string;
}

export class CreateRefuseAmountDto {
  @ApiProperty({
    description: 'Sequential number generated by the database identifying the food records.',
    required: false,
  })
  @IsString()
  public FoodID?: string;

  @ApiProperty({
    description: 'Sequential number generated by the database for the refuse descriptions',
    required: false,
  })
  @IsString()
  public RefuseID?: string;

  @ApiProperty({
    description: 'Percent refuse',
    required: false,
  })
  @IsString()
  public RefuseAmount?: string;

  @ApiProperty({
    description: 'Date the data was entered into the database',
    required: false,
  })
  @IsString()
  public RefuseDateOfEntry?: string;
}

export class CreateYieldAmountDto {
  @ApiProperty({
    description: 'Sequential number generated by the database identifying the food records.',
    required: false,
  })
  @IsString()
  public FoodID?: string;

  @ApiProperty({
    description: 'Sequential number generated by the database for the yield descriptions',
    required: false,
  })
  @IsString()
  public YieldID?: string;

  @ApiProperty({
    description: 'Yield from refuse and/or cooking losses',
    required: false,
  })
  @IsString()
  public YieldAmount?: string;

  @ApiProperty({
    description: 'Date the data was entered into the database',
    required: false,
  })
  @IsString()
  public YieldDateofEntry?: string;
}

export class CreateFoodGroupDto {
  @ApiProperty({
    description: 'Sequential number generated by the database for the food groups',
    required: false,
  })
  @IsString()
  public FoodGroupID?: string;

  @ApiProperty({
    description: 'Identifier code for the Canadian Nutrient File food groups. There are 23 different CNF food groups',
    required: false,
  })
  @IsString()
  public FoodGroupCode?: string;

  @ApiProperty({
    description: 'Food group name in English',
    required: false,
  })
  @IsString()
  public FoodGroupName?: string;

  @ApiProperty({
    description: 'Food group name in French',
    required: false,
  })
  @IsString()
  public FoodGroupNameF?: string;
}

export class CreateFoodSourceDto {
  @ApiProperty({
    description: 'Sequential number generated by the database for the food sources',
    required: false,
  })
  @IsString()
  public FoodSourceID?: string;

  @ApiProperty({
    description:
      'Identifier code for the food sources descriptions. Food sources give an indication as to Canadian content',
    required: false,
  })
  @IsString()
  public FoodSourceCode?: string;

  @ApiProperty({
    description: 'Food Source description in English',
    required: false,
  })
  @IsString()
  public FoodSourceDescription?: string;

  @ApiProperty({
    description: 'Food Source description in French',
    required: false,
  })
  @IsString()
  public FoodSourceDescriptionF?: string;
}

export class CreateNutrientNameDto {
  @ApiProperty({
    description: 'Sequential number generated by the database for the nutrient names',
    required: false,
  })
  @IsString()
  public NutrientID?: string;

  @ApiProperty({
    description: 'Identifier code for nutrient name descriptions',
    required: false,
  })
  @IsString()
  public NutrientCode?: string;

  @ApiProperty({
    description: 'Nutrient symbol or abbreviation of the nutrients. They may differ from international nomenclature.',
    required: false,
  })
  @IsString()
  public NutrientSymbol?: string;

  @ApiProperty({
    description: 'Unit of measure (e.g., mg, g, mcg)',
    required: false,
  })
  @IsString()
  public NutrientUnit?: string;

  @ApiProperty({
    description: 'Nutrient name in English',
    required: false,
  })
  @IsString()
  public NutrientName?: string;

  @ApiProperty({
    description: 'Nutrient name in French',
    required: false,
  })
  @IsString()
  public NutrientNameF?: string;

  @ApiProperty({
    description: 'International Network of Food Data Systems (INFOODS) Tagnames.',
    required: false,
  })
  @IsString()
  public Tagname?: string;

  @ApiProperty({
    description: 'Number of decimal places used in the rounding of the nutrient value',
    required: false,
  })
  @IsString()
  public NutrientDecimals?: string;
}

export class CreateNutrientSourceDto {
  @ApiProperty({
    description: 'Sequential number generated by the database for the nutrient sources',
    required: false,
  })
  @IsString()
  public NutrientSourceID?: string;

  @ApiProperty({
    description: 'Identifier code for nutrient source descriptions.',
    required: false,
  })
  @IsString()
  public NutrientSourceCode?: string;

  @ApiProperty({
    description: 'Nutrient source description in English',
    required: false,
  })
  @IsString()
  public NutrientSourceDescription?: string;

  @ApiProperty({
    description: 'Nutrient source description in French',
    required: false,
  })
  @IsString()
  public NutrientSourceDescriptionF?: string;
}

export class CreateMeasureNameDto {
  @ApiProperty({
    description: 'Sequential number generated by the database for the measure descriptions',
    required: false,
  })
  @IsString()
  public MeasureID?: string;

  @ApiProperty({
    description: 'Measure description in English',
    required: false,
  })
  @IsString()
  public MeasureDescription?: string;

  @ApiProperty({
    description: 'Measure description in French',
    required: false,
  })
  @IsString()
  public MeasureDescriptionF?: string;
}

export class CreateRefuseNameDto {
  @ApiProperty({
    description: 'Sequential number generated by the database for the refuse descriptions',
    required: false,
  })
  @IsString()
  public RefuseID?: string;

  @ApiProperty({
    description: 'Refuse description in English',
    required: false,
  })
  @IsString()
  public RefuseDescription?: string;

  @ApiProperty({
    description: 'Refuse description in French',
    required: false,
  })
  @IsString()
  public RefuseDescriptionF?: string;
}

export class CreateYieldNameDto {
  @ApiProperty({
    description: 'Sequential number generated by the database for the yield descriptions',
    required: false,
  })
  @IsString()
  public YieldID?: string;

  @ApiProperty({
    description: 'Yield description in English',
    required: false,
  })
  @IsString()
  public YieldDescription?: string;

  @ApiProperty({
    description: 'Yield description in French',
    required: false,
  })
  @IsString()
  public YieldDescriptionF?: string;
}

export class CnfNutrientCompoDto {
  @ApiProperty({
    description: 'name of nutrient in cnf',
    example: 'PROTÉINES',
  })
  @IsString()
  public nutrientNameF?: string;

  @ApiProperty({
    description: 'nutrient value amount in cnf',
    example: 9,
  })
  @IsNumber()
  public nutrientValue?: number;

  @ApiProperty({
    description: 'nutrient unit in cnf',
    example: 'g',
  })
  @IsString()
  public unit?: string;
}
export class CnfIngredientCompoDto {
  @ApiProperty({
    description: 'name of ingredient in cnf',
    example: 'Soufflé au fromage',
  })
  @IsString()
  public foodDescriptionF?: string;

  @ApiProperty({
    description: 'ingredient code in cnf',
    example: 2,
  })
  @IsNumber()
  public foodCode?: number;

  @ApiProperty({ description: 'Nutrients composition', isArray: true, type: CnfNutrientCompoDto })
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => CnfNutrientCompoDto)
  compo: CnfNutrientCompoDto[];
}
