import { ApiProperty, IsEnum, IsInt, IsObject, IsString } from './decorators-nest';

export enum CrispEvent {
  /** Message updated */
  'message:updated' = 'message:updated',
  /** Message Send (from the Visitor) */
  'message:send' = 'message:send',
  /** Message Received (from the Operator) */
  'message:received' = 'message:received',
  /** Message Acknowledge Read Send */
  'message:acknowledge:read:send' = 'message:acknowledge:read:send',
  /** When messages sent by operators were not read in due time by visitor */
  'message:acknowledge:read:received' = 'message:acknowledge:read:received',
  /** When messages sent by visitor were not read in due time by an operator */
  'message:notify:unread:send' = 'message:notify:unread:send',
  /** Message Notify Unread Received */
  'message:notify:unread:received' = 'message:notify:unread:received',
  /** Session Set Email */
  'session:set_email' = 'session:set_email',
  /** Session Set Phone */
  'session:set_phone' = 'session:set_phone',
  /** Session Set Address */
  'session:set_address' = 'session:set_address',
  /** Session Set Data */
  'session:set_data' = 'session:set_data',
  /** Session Set Avatar */
  'session:set_avatar' = 'session:set_avatar',
  /** Session Set Nickname */
  'session:set_nickname' = 'session:set_nickname',
  /** Session Set State */
  'session:set_state' = 'session:set_state',
  /** Session Set Block */
  'session:set_block' = 'session:set_block',
  /** Session Set Segments */
  'session:set_segments' = 'session:set_segments',
  /** Session Removed */
  'session:removed' = 'session:removed',
  /** Session Sync Geolocation */
  'session:sync:geolocation' = 'session:sync:geolocation',
  /** Session Sync System */
  'session:sync:system' = 'session:sync:system',
  /** Session Sync Network */
  'session:sync:network' = 'session:sync:network',
  /** Session Sync Timezone */
  'session:sync:timezone' = 'session:sync:timezone',
  /** Session Sync Local */
  'session:sync:locales' = 'session:sync:locales',
  /** Session Sync Events */
  'session:sync:events' = 'session:sync:events',
  /** Session Sync Rating */
  'session:sync:rating' = 'session:sync:rating',
  /** People Profile Created */
  'people:profile:created' = 'people:profile:created',
  /** People Profile Updated */
  'people:profile:updated' = 'people:profile:updated',
  /** People Profile Removed */
  'people:profile:removed' = 'people:profile:removed',
  /** People Bind Session */
  'people:bind:session' = 'people:bind:session',
  /** People Sync Profile */
  'people:sync:profile' = 'people:sync:profile',
  /** Campaign Progress */
  'campaign:progress' = 'campaign:progress',
  /** Campaign Dispatched */
  'campaign:dispatched' = 'campaign:dispatched',
  /** Campaign Running */
  'campaign:running' = 'campaign:running',
  /** Status Health Changed */
  'status:health:changed' = 'status:health:changed',
  /** Email Subscribe */
  'email:subscribe' = 'email:subscribe',
  /** Email Track View */
  'email:track:view' = 'email:track:view',
  /** Plugin Event */
  'plugin:event' = 'plugin:event',
}

interface CrispData {
  session_id: string;
  website_id: string;
  [key: string]: unknown;
}

/** @see https://help.crisp.chat/en/article/how-to-use-webhooks-itsagz/ */
export class WebhookDto {
  @ApiProperty({
    description: 'The Website ID the event occured in',
    externalDocs: { url: 'https://docs.crisp.chat/api/v1/#website' },
    type: 'string',
    format: 'uuid',
    example: '124ed8db-25e5-47d7-a4f3-b1d9fa934650',
  })
  @IsString()
  website_id: string;

  @ApiProperty({
    description: 'The event namespace',
    externalDocs: {
      url: 'https://help.crisp.chat/en/article/how-to-use-webhooks-itsagz/#1-which-events-can-be-handled',
    },
    enum: CrispEvent,
    example: CrispEvent['message:notify:unread:send'],
  })
  @IsEnum(CrispEvent)
  event: CrispEvent;

  @ApiProperty({
    description: 'The Web Hook payload (format depends on event namespace)',
    example: {
      session_id: 'session_bc3c4171-c247-401d-8f6d-3e34dc9aee81',
      website_id: '124ed8db-xxxx-xxxx-xxxx-124ed8db',
    },
  })
  @IsObject()
  data: CrispData;

  @ApiProperty({
    description: 'The UNIX timestamp of the event (in milliseconds)',
    example: Date.now(),
  })
  @IsInt()
  timestamp: number;
}
