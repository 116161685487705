import { Type } from '@nestjs/common';
import 'reflect-metadata';

// class-validator monkey patches
export function ApiProperty(...args): PropertyDecorator {
  return function () {};
}

export function ApiPropertyOptional(...args): PropertyDecorator {
  return function () {};
}

export function PartialType<T>(classRef: Type<T>): Type<Partial<T>> {
  abstract class PartialTypeClass {}
  return PartialTypeClass as Type<Partial<T>>;
}

export function OmitType<T, K extends keyof T>(
  classRef: Type<T>,
  keys: readonly K[]
): Type<Omit<T, typeof keys[number]>> {
  abstract class OmitTypeClass {}
  return OmitTypeClass as Type<Omit<T, typeof keys[number]>>;
}

export function PickType<T, K extends keyof T>(
  classRef: Type<T>,
  keys: readonly K[]
): Type<Pick<T, typeof keys[number]>> {
  abstract class PickTypeClass {}
  return PickTypeClass as Type<Pick<T, typeof keys[number]>>;
}

export function IsString(validationOptions?: ValidationOptions): PropertyDecorator {
  return function () {};
}

export function IsUrl(options?: Object, validationOptions?: ValidationOptions): PropertyDecorator {
  return function () {};
}

export function IsDefined(validationOptions?: ValidationOptions): PropertyDecorator {
  return function () {};
}

export function IsEmail(validationOptions?: ValidationOptions): PropertyDecorator {
  return function () {};
}

export function Matches(patter: RegExp, validationOptions?: ValidationOptions): PropertyDecorator {
  return function () {};
}

export function MinLength(min: number, validationOptions?: ValidationOptions): PropertyDecorator {
  return function () {};
}

export function IsEnum(entity: Object, validationOptions?: ValidationOptions): PropertyDecorator {
  return function () {};
}

export function IsNotEmpty(validationOptions?: ValidationOptions): PropertyDecorator {
  return function () {};
}

export function IsOptional(validationOptions?: ValidationOptions): PropertyDecorator {
  return function () {};
}

export function IsPhoneNumber(region: string | null, validationOptions?: ValidationOptions): PropertyDecorator {
  return function () {};
}

export function ValidateIf(
  condition: (object: any, value: any) => boolean,
  validationOptions?: ValidationOptions
): PropertyDecorator {
  return function () {};
}

export function IsBoolean(validationOptions?: ValidationOptions): PropertyDecorator {
  return function () {};
}

export function IsNumber(): PropertyDecorator {
  return function () {};
}

export function IsUUID(): PropertyDecorator {
  return function () {};
}

export function IsObject(): PropertyDecorator {
  return function () {};
}

export function Max(maxValue: number, validationOptions?: ValidationOptions): PropertyDecorator {
  return function () {};
}

export function MaxLength(maxLength: number, validationOptions?: ValidationOptions): PropertyDecorator {
  return function () {};
}

export function Min(minValue: number, validationOptions?: ValidationOptions): PropertyDecorator {
  return function () {};
}

export function IsDate(validationOptions?: ValidationOptions): PropertyDecorator {
  return function () {};
}

export function IsDateString(validationOptions?: ValidationOptions): PropertyDecorator {
  return function () {};
}

export function IsInt(validationOptions?: ValidationOptions): PropertyDecorator {
  return function () {};
}

export function registerDecorator(options: ValidationDecoratorOptions): PropertyDecorator {
  return function () {};
}

export function IsIn(values: any[], validationOptions?: ValidationOptions): PropertyDecorator {
  return function () {};
}

export function ValidateNested(validationOptions?: ValidationOptions): PropertyDecorator {
  return function () {};
}

export function IsArray(validationOptions?: ValidationOptions): PropertyDecorator {
  return function () {};
}

interface ValidationArguments {
  value: any;
  constraints: any[];
  targetName: string;
  object: Object;
  property: string;
}
export interface ValidationOptions {
  each?: boolean;
  message?: string | ((validationArguments: ValidationArguments) => string);
  groups?: string[];
  always?: boolean;
  context?: any;
}

interface ValidatorConstraintInterface {
  validate(value: any, validationArguments?: ValidationArguments): Promise<boolean> | boolean;
  defaultMessage?(validationArguments?: ValidationArguments): string;
}
interface ValidationDecoratorOptions {
  target: Function;
  propertyName: string;
  name?: string;
  async?: boolean;
  options?: ValidationOptions;
  constraints?: any[];
  validator: ValidatorConstraintInterface | Function;
}

// class-transformer monkey patches
export function Type(foo: any): (target: any, key: string) => void {
  return function () {};
}
