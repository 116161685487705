import { ApiProperty, IsString, IsNumber, IsArray, ValidateNested, Type } from './decorators-nest';

export class CreateAlimDto {
  @ApiProperty({
    description: 'code of the food',
    required: false,
  })
  @IsString()
  public alim_code?: string;

  @ApiProperty({
    description: 'name of the food in French',
    required: false,
  })
  @IsString()
  public alim_nom_fr?: string;

  @ApiProperty({
    description: 'name of the food in French index',
    required: false,
  })
  @IsString()
  public alim_nom_index_fr?: string;

  @ApiProperty({
    description: 'name of the food in English',
    required: false,
  })
  @IsString()
  public alim_nom_eng?: string;

  @ApiProperty({
    description: 'code of the food group',
    required: false,
  })
  @IsString()
  public alim_grp_code?: string;

  @ApiProperty({
    description: 'code of the food subgroup',
    required: false,
  })
  @IsString()
  public alim_ssgrp_code?: string;

  @ApiProperty({
    description: 'code of the food sub-subgroup',
    required: false,
  })
  @IsString()
  public alim_ssssgrp_code?: string;
}

export class AlimDto extends CreateAlimDto {
  @ApiProperty({
    type: 'number',
  })
  id?: number;
}

export class CreateAlimGrpDto {
  @ApiProperty({
    description: 'code of the food group',
    required: false,
  })
  @IsString()
  public alim_grp_code?: string;

  @ApiProperty({
    description: 'name of the food group in French',
    required: false,
  })
  @IsString()
  public alim_grp_nom_fr?: string;

  @ApiProperty({
    description: 'name of the food group in English',
    required: false,
  })
  @IsString()
  public alim_grp_nom_eng?: string;

  @ApiProperty({
    description: 'code of the food subgroup',
    required: false,
  })
  @IsString()
  public alim_ssgrp_code?: string;

  @ApiProperty({
    description: 'name of the food subgroup in French',
    required: false,
  })
  @IsString()
  public alim_ssgrp_nom_fr?: string;

  @ApiProperty({
    description: 'name of the food subgroup in English',
    required: false,
  })
  @IsString()
  public alim_ssgrp_nom_eng?: string;

  @ApiProperty({
    description: 'code of the food sub-subgroup',
    required: false,
  })
  @IsString()
  public alim_ssssgrp_code?: string;

  @ApiProperty({
    description: 'name of the food sub-subgroup in French',
    required: false,
  })
  @IsString()
  public alim_ssssgrp_nom_fr?: string;

  @ApiProperty({
    description: 'name of the food subb-subgroup in English',
    required: false,
  })
  @IsString()
  public alim_ssssgrp_nom_eng?: string;
}

export class CreateCompoDto {
  @ApiProperty({
    description: 'code of the food',
    required: false,
  })
  @IsString()
  public alim_code?: string;

  @ApiProperty({
    description: 'code of the component',
    required: false,
  })
  @IsString()
  public const_code?: string;

  @ApiProperty({
    description: `it can be a value, a max value (example : "<10"), the indication "trace" or a hyphen if the value is missing`,
    required: false,
  })
  @IsString()
  public teneur?: string;

  @ApiProperty({
    description: 'minimum value observed in the data-sources',
    required: false,
  })
  @IsString()
  min?: string;

  @ApiProperty({
    description: 'maximum value observed in the data-sources',
    required: false,
  })
  @IsString()
  max?: string;

  @ApiProperty({
    description: `confidence code, which characterizes the quality of the average content_value(A = very reliable to D = less reliable)`,
    required: false,
  })
  @IsString()
  code_confiance?: string;

  @ApiProperty({
    description: 'code of the data-sources',
    required: false,
  })
  @IsString()
  source_code?: string;
}

export class CreateConstDto {
  @ApiProperty({
    description: 'code of the component',
    required: false,
  })
  @IsString()
  public const_code?: string;

  @ApiProperty({
    description: 'name of the component in French (includes unit)',
    required: false,
  })
  @IsString()
  public const_nom_fr?: string;

  @ApiProperty({
    description: 'name of the component in English (includes unit)',
    required: false,
  })
  @IsString()
  public const_nom_eng?: string;
}

export class CreateSourceDto {
  @ApiProperty({
    description: 'code of data-sources',
    required: false,
  })
  @IsString()
  public source_code?: string;

  @ApiProperty({
    description: 'name of data-sources',
    required: false,
  })
  @IsString()
  public ref_citation?: string;
}

export class CiqualNutrientCompoDto {
  @ApiProperty({
    description: 'name of nutrient in ciqual',
    example: 'AG 18:1 9c (n-9), oléique (g/100 g)',
  })
  @IsString()
  public const_nom_fr?: string;

  @ApiProperty({
    description: 'nutrient code in ciqual',
    example: 41819,
  })
  @IsString()
  public teneur?: string;
}
export class CiqualIngredientCompoDto {
  @ApiProperty({
    description: 'name of ingredient in ciqual',
    example: 'Eau de vie, type calvados',
  })
  @IsString()
  public alim_nom_fr?: string;

  @ApiProperty({
    description: 'ingredient code in ciqual',
    example: 1024,
  })
  @IsNumber()
  public alim_code?: number;

  @ApiProperty({ description: 'Nutrients composition', isArray: true, type: CiqualNutrientCompoDto })
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => CiqualNutrientCompoDto)
  compo: CiqualNutrientCompoDto[];
}
