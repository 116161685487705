import { ApiProperty, IsBoolean, IsEmail, IsNotEmpty, IsString } from './decorators-nest';

export class ReferralDto {
  @IsNotEmpty()
  @IsString()
  @ApiProperty()
  id: string;

  @IsNotEmpty()
  @IsEmail()
  @ApiProperty()
  referredEmail: string;

  @IsNotEmpty()
  @IsString()
  @ApiProperty({ example: 'PROMO123' })
  promoCode: string;

  @IsBoolean()
  @ApiProperty({ example: false })
  isPromoCodeUsed: boolean;
}

export class SponsorDto {
  @IsEmail()
  @ApiProperty()
  email: string;

  @IsString()
  @ApiProperty({ example: 'PROMO123' })
  promoCode: string;
}

export class CreateSponsorDto {
  @IsEmail()
  @ApiProperty({ description: 'Email of the sponsor' })
  email: string;
}

export class CreateReferralDto {
  @IsEmail()
  @IsNotEmpty()
  sponsorEmail: string;

  @IsEmail()
  @IsNotEmpty()
  referredEmail: string;
}
