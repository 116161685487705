import {
  ApiProperty,
  IsBoolean,
  IsDateString,
  IsEmail,
  IsNumber,
  IsOptional,
  IsString,
  Matches,
  MinLength,
  PartialType,
  Type,
  MaxLength,
  ValidateIf,
  IsEnum,
} from './decorators-nest';

/**
 * Patient role
 */
export enum PatientRole {
  PATIENT = 'patient',
}

/** Enum for Patient Group */
export enum PatientGroup {
  TEST = 'TEST',
  LIVE = 'LIVE',
}

export enum PatientSortFields {
  FIRSTNAME = 'firstname',
  CREATED_DATE = 'createdDate',
  EMAIL = 'email',
  STAFFNAME = 'staffname',
}

enum MembershipPeriod {
  MONTHLY = 'monthly',
  THREE_MONTHS = 'three_months',
  SIX_MONTHS = 'six_months',
}

enum ProgramType {
  ESSENTIAL = 'essentiel',
  AGE = 'age',
  SOPK = 'sopk',
}

export class MembershipDto {
  @ApiProperty({ description: 'Subscribed to Essential Food Supplements' })
  essentialSupplements: boolean;

  @ApiProperty({ description: 'Subscribed to Essential Coaching' })
  essentialCoaching: boolean;

  @ApiProperty({ description: 'Subscribed to option Coaching' })
  optionCoaching: boolean;

  @ApiProperty({ description: 'Subscribed to option 35+ years old' })
  option35: boolean;

  @ApiProperty({ description: 'Subscribed to option weight' })
  optionWeigth: boolean;

  @ApiProperty({ description: 'Type of the subscription', enum: MembershipPeriod })
  @IsEnum(MembershipPeriod)
  membershipPeriod: MembershipPeriod;

  @ApiProperty({ description: 'Type of the program', enum: ProgramType })
  @IsEnum(ProgramType)
  programType: ProgramType;
}

/**
 * Patient creation payload
 */
export class CreatePatientDto {
  @IsEmail()
  @ApiProperty({
    description: 'Email of the patient to create',
    example: 'my@email.com',
  })
  email: string;

  /**
   * Password complexity :
   * - Minimum length 8 chars,
   * - minimum 3 of the 4 categories : lowercase, uppercase, number, special chars
   */
  @IsString()
  @MinLength(8)
  @Matches(
    /(((?=.*\W+)(?=.*[A-Z])(?=.*[a-z]))|((?=.*\d)(?=.*[A-Z])(?=.*[a-z]))|((?=.*\d)(?=.*\W+)(?=.*[a-z]))|((?=.*\d)(?=.*\W+)(?=.*[A-Z]))).*$/,
    {
      message:
        'Password must be at least 8 characters long, and contain at least 3 of the 4 categories : lowercase, uppercase, number, special chars',
    }
  )
  @ApiProperty({
    description: 'Password of the patient to create',
    example: 'password',
  })
  password: string;

  @IsBoolean()
  @ApiProperty({
    description: 'Tell if the patient has accepted the terms and conditions of the service',
  })
  agreestoTerms: boolean;

  @IsOptional()
  @ApiProperty({
    description: 'Array of firebase registration tokens',
    required: false,
  })
  public firebaseTokens?: string[];

  @ApiProperty({
    description: 'firstname',
    example: 'Jane',
  })
  @IsString()
  firstname: string;

  @ApiProperty({
    description: 'lastname',
    example: 'Doe',
  })
  @IsString()
  lastname: string;

  @ApiProperty({
    description: 'birthdate',
    required: false,
  })
  @IsDateString()
  @IsOptional()
  birthdate?: Date;

  @ApiProperty({
    description: 'Address Line 1',
    example: '134 rue du temple',
  })
  @IsString()
  public addressLine1: string;

  @ApiProperty({
    description: 'Address Line 2',
    example: 'ACME corporation',
    required: false,
  })
  @IsOptional()
  public addressLine2: string;

  @ApiProperty({
    description: 'Zip Code',
    example: '75003',
  })
  @IsString()
  public zipCode: string;

  @ApiProperty({
    description: 'City',
    example: 'Paris',
  })
  @IsString()
  public city: string;

  @ApiProperty({
    description: 'Country',
    example: 'France',
  })
  @IsString()
  public country: string;

  @ApiProperty({
    description: 'Phone Number',
    example: '+33600000000',
  })
  @IsString()
  public phoneNumber: string;

  @ApiProperty({
    description: 'group (TEST or LIVE)',
    enum: PatientGroup,
    required: false,
  })
  @IsOptional()
  public group?: PatientGroup;

  @ApiProperty({
    description: 'Enable/Disable notification for patient profil update',
    required: false,
  })
  @IsOptional()
  @IsBoolean()
  pushSurveyAlerts?: boolean;

  @ApiProperty({
    description: 'Enable/Disable notification for new message',
    required: false,
  })
  @IsOptional()
  @IsBoolean()
  pushChatAlerts?: boolean;

  @ApiProperty({
    description: 'Flag active/inactive patients',
    required: false,
  })
  @IsOptional()
  @IsBoolean()
  isArchived?: boolean;

  @ApiProperty({
    description: 'Enable/Disable pickup point',
    required: false,
  })
  @IsOptional()
  @IsBoolean()
  isPickup?: boolean;

  @ApiProperty({
    description: 'Pickup point id',
    example: '123456789',
  })
  @IsOptional()
  @IsString()
  public pickupId?: string;

  @ApiProperty({
    description: 'Pickup point address',
    example: 'AATHI, 3 cour des maraichers, 93120, LA COURNEUVE',
  })
  @IsOptional()
  @IsString()
  public pickupAddress?: string;

  @ApiProperty({
    description: 'Enable/Disable notification for patient profil update',
    type: 'boolean',
  })
  isPregnant: boolean;
}

export class UpdatePatientToolsheetsDto {}

/**
 * Patient update payload
 */
export class UpdatePatientDto extends PartialType(CreatePatientDto) {}

/**
 * Patient interface
 */

export class PatientDto {
  @ApiProperty({
    description: 'UUID of the patient',
    example: '124ed8db-25e5-47d7-a4f3-b1d9fa934650',
    required: false,
  })
  id: string;

  @ApiProperty({
    description: 'Email of the patient',
    example: 'my@email.com',
    required: false,
  })
  email: string;

  @ApiProperty({
    description: 'firstname',
    example: 'Jane',
    required: false,
  })
  firstname: string;

  @ApiProperty({
    description: 'lastname',
    example: 'Doe',
    required: false,
  })
  lastname: string;

  @ApiProperty({
    description: 'birthdate',
    required: false,
  })
  birthdate?: Date;

  @ApiProperty({
    description: 'group (TEST or LIVE)',
    enum: PatientGroup,
    required: false,
  })
  public group?: PatientGroup;

  @ApiProperty({
    description: 'created at timestamp',
    required: false,
  })
  createdDate?: Date;

  @ApiProperty({
    description: 'updated at timestamp',
    required: false,
  })
  updatedDate?: Date;

  @ApiProperty({
    description: 'Tell if the patient has accepted the terms and conditions of the service',
    required: false,
  })
  agreestoTerms: boolean;

  @ApiProperty({
    description: 'Address Line 1',
    required: false,
  })
  public addressLine1: string;

  @ApiProperty({
    description: 'Address Line 2',
    required: false,
  })
  public addressLine2?: string;

  @ApiProperty({
    description: 'Zip Code',
    required: false,
  })
  public zipCode: string;

  @ApiProperty({
    description: 'City',
    required: false,
  })
  public city: string;

  @ApiProperty({
    description: 'Country',
    required: false,
  })
  public country: string;

  @ApiProperty({
    description: 'Phone Number',
    required: false,
  })
  public phoneNumber: string;

  @ApiProperty({
    description: 'Assigned staff id',
    required: false,
  })
  public staffId?: string;

  @ApiProperty({
    description: 'Crisp conversation link',
    nullable: true,
    required: false,
  })
  public crispConversation?: string;

  @ApiProperty({
    description: 'Stripe customer ID',
    required: false,
  })
  stripeCustomerId: string;

  @ApiProperty({
    description: 'Stripe subscription ID',
    nullable: true,
    required: false,
  })
  stripeSubscriptionId: string;

  @ApiProperty({
    description: 'Shopify customer ID',
    required: false,
  })
  shopifyCustomerId: string;

  @ApiProperty({
    description: 'Shopify subscription ID',
    nullable: true,
    required: false,
  })
  shopifySubscriptionId: string;

  @ApiProperty({
    description: 'Current BeMum membership',
  })
  @Type(() => MembershipDto)
  currentMembership: MembershipDto;

  @ApiProperty({
    description: 'Future BeMum membership',
  })
  @Type(() => MembershipDto)
  futureMembership?: MembershipDto;

  @ApiProperty({
    description: 'Enable/Disable notification for patient profil update',
    required: false,
  })
  pushSurveyAlerts?: boolean;

  @ApiProperty({
    description: 'Enable/Disable notification for new message',
    required: false,
  })
  pushChatAlerts?: boolean;

  @ApiProperty({
    description: 'Array of firebase registration tokens',
    required: false,
  })
  public firebaseTokens?: string[];

  @ApiProperty({
    description: 'Sign up HEALTH survey completed or not',
    required: false,
  })
  healthSurveyFilled: boolean;

  @ApiProperty({
    description: 'Sign up MEMORY survey completed or not',
    required: false,
  })
  memorySurveyFilled: boolean;

  @ApiProperty({
    description: 'Sign up MOOD survey completed or not',
    required: false,
  })
  moodSurveyFilled: boolean;

  @ApiProperty({
    description: 'Sign up OXIDATIVE survey completed or not',
    required: false,
  })
  oxidativeSurveyFilled: boolean;

  @ApiProperty({
    description: 'Sign up PHYSICAL ACTIVITY survey completed or not',
    required: false,
  })
  physicalActivitySurveyFilled: boolean;

  @ApiProperty({
    description: 'Sign up PROFILE survey completed or not',
    required: false,
  })
  profileSurveyFilled: boolean;

  @ApiProperty({
    description: 'Sign up REPRODUCTIVE survey completed or not',
    required: false,
  })
  reproductiveSurveyFilled: boolean;

  @ApiProperty({
    description: 'Sign up SLEEP survey completed or not',
    required: false,
  })
  sleepSurveyFilled: boolean;

  @ApiProperty({
    description: 'Sign up STRESS survey completed or not',
    required: false,
  })
  stressSurveyFilled: boolean;

  @ApiProperty({
    description: 'FODD PREFERENCE survey completed or not',
    required: false,
  })
  foodPreferencesSurveyFilled: boolean;

  @ApiProperty({
    description: 'When true, patient can submit followUp surveys',
    type: 'boolean',
  })
  updateProfile: boolean;

  @ApiProperty({
    description: 'Flag active/inactive patients',
    type: 'boolean',
  })
  isArchived: boolean;

  @ApiProperty({
    description: 'When true, the package will be delivered to a relay point',
    type: 'boolean',
  })
  isPickup: boolean;

  @ApiProperty({
    description: 'Pickup point ID',
    required: false,
  })
  public pickupId: string;

  @ApiProperty({
    description: 'Pickup point address',
    required: false,
  })
  public pickupAddress: string;

  @ApiProperty({
    description: 'Enable/Disable notification for patient profil update',
    type: 'boolean',
  })
  isPregnant: boolean;
}

/**
 * Patient staff update payload
 */
export class UpdatePatientStaffDto {
  @IsString()
  @ApiProperty({
    description: 'Id of selected staff for a given patient',
  })
  staffId: string;
}

export class MetricDto {
  @ApiProperty({
    description: 'ISO 8601 date string',
    type: 'string',
    format: 'date-time',
    example: '2017-07-21T17:32:28Z',
  })
  date: string;

  @ApiProperty({ description: 'metric value' })
  value: number;
}

/**
 * Patient metrics based on signup and follow-up surveys
 */
export class PatientMetricsDto {
  @ApiProperty({ type: MetricDto, isArray: true })
  WEIGHT: MetricDto[];

  @ApiProperty({
    description: 'Body Mass Index',
    type: MetricDto,
    isArray: true,
  })
  BMI: MetricDto[];

  @ApiProperty({ type: MetricDto, isArray: true })
  @IsNumber()
  WAIST: MetricDto[];

  @ApiProperty({ type: MetricDto, isArray: true })
  @IsNumber()
  HIPS: MetricDto[];

  @ApiProperty({
    description: 'Physical Activity Level',
    type: MetricDto,
    isArray: true,
  })
  PAL: MetricDto[];
}

/**
 * Patient billing info
 */
export class BillingInfo {
  @ApiProperty({
    description: 'Date (ISO8601) on which a new invoice for this subscription will be created',
    example: '2017-07-21T17:32:28Z',
    type: 'date-time',
    required: false,
  })
  next_billing?: string;

  @ApiProperty({
    description: 'The last four digits of the card',
    example: '4242',
    type: 'string',
    required: false,
  })
  last_digit?: string;
}

export class CoachingDropOutDto {
  @ApiProperty({
    description: "Le coaching ne m'intéresse pas",
  })
  @IsBoolean()
  notInterested: boolean;

  @ApiProperty({
    description: 'Le prix ne me convient pas',
  })
  @IsBoolean()
  priceTooHigh: boolean;

  @ApiProperty({
    description: 'Je ne suis pas suffisamment disponible',
  })
  @IsBoolean()
  notAvailable: boolean;

  @ApiProperty({
    description: 'Je ne suis pas satisfaite du coaching',
  })
  @IsBoolean()
  notHappy: boolean;

  @ApiProperty({
    description: 'Autre',
  })
  @IsBoolean()
  other: boolean;

  @ApiProperty({
    description: 'Détails sur la raison du drop out du coaching essentiel',
    maxLength: 200,
  })
  @IsString()
  @MaxLength(200)
  @ValidateIf((o: CoachingDropOutDto) => o.other === true)
  @IsOptional()
  details: string;
}

export class ProgramDropOutDto {
  @ApiProperty({
    description: 'Je suis enceinte',
  })
  @IsBoolean()
  pregnant: boolean;

  @ApiProperty({
    description: 'Je pense avoir fait le tour du programme',
  })
  @IsBoolean()
  completedProgram: boolean;

  @ApiProperty({
    description: 'Je trouve le programme trop cher',
  })
  @IsBoolean()
  priceTooHigh: boolean;

  @ApiProperty({
    description: 'Je ne suis pas satisfaite',
  })
  @IsBoolean()
  notHappy: boolean;

  @ApiProperty({
    description: "J'arrête les essais bébés",
  })
  @IsBoolean()
  stoppedTrying: boolean;

  @ApiProperty({
    description: "On m'a conseillé autre chose",
  })
  @IsBoolean()
  anotherAdvice: boolean;

  @ApiProperty({
    description: "C'est temporaire, je vais bientôt me réabonner",
  })
  @IsBoolean()
  temporary: boolean;

  @ApiProperty({
    description: 'Autre',
  })
  @IsBoolean()
  other: boolean;

  @ApiProperty({
    description: 'Détails sur la raison du drop out du programme',
    maxLength: 200,
  })
  @IsString()
  @MaxLength(200)
  @ValidateIf((o: ProgramDropOutDto) => o.other === true)
  @IsOptional()
  details: string;
}

export class SupplementsDropOutDto {
  @ApiProperty({
    description: "Je prends déjà d'autres compléments alimentaires",
  })
  @IsBoolean()
  otherSupplements: boolean;

  @ApiProperty({
    description: 'Je trouve ces compléments trop chers',
  })
  @IsBoolean()
  priceTooHigh: boolean;

  @ApiProperty({
    description: 'La composition de ces compléments ne me correspond pas',
  })
  @IsBoolean()
  notFitted: boolean;

  @ApiProperty({
    description: 'Je ne souhaite pas prendre de compléments alimentaires',
  })
  @IsBoolean()
  wontTakeSupplements: boolean;

  @ApiProperty({
    description: 'Je suis enceinte',
  })
  @IsBoolean()
  pregnant: boolean;

  @ApiProperty({
    description: 'Je digère mal ces compléments',
  })
  @IsBoolean()
  difficultDigest: boolean;

  @ApiProperty({
    description: 'Je trouve le nombre de gélules trop important',
  })
  @IsBoolean()
  tooMany: boolean;

  @ApiProperty({
    description: 'Je les oublie trop souvent',
  })
  @IsBoolean()
  oftenForget: boolean;

  @ApiProperty({
    description: 'Autre',
  })
  @IsBoolean()
  other: boolean;

  @ApiProperty({
    description: 'Détails sur la raison du drop out des compléments essentiels',
    maxLength: 200,
  })
  @IsString()
  @MaxLength(200)
  @ValidateIf((o: SupplementsDropOutDto) => o.other === true)
  @IsOptional()
  details: string;
}

export class ProspectNumberMessageDto {
  @ApiProperty({
    description: '',
    maxLength: 200,
  })
  @IsString()
  text: string;
}

export class NotificationDto {
  @ApiProperty({
    description: 'Notification title',
    maxLength: 200,
  })
  @IsString()
  title: string;

  @ApiProperty({
    description: 'Notification body',
    maxLength: 200,
  })
  @IsString()
  message: string;

  @ApiProperty({
    description: 'Slack Message',
  })
  @IsString()
  slackMessage: string;
}
