import { ApiProperty, IsDefined, IsEmail, IsString, Matches, MinLength } from './decorators-nest';
import { PatientRole } from './patient.interface';
import { StaffRole } from './staff.interface';

/**
 * In case it isn't obvious at first sight, this regex tests :
 * - Minimum length 8 chars,
 * - At least 3 of the 4 categories : lowercase, uppercase, number, special chars
 */
const passwordStrengthRegex = /(?=.{8,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/;

/** Request body used to create a new jwt */
export class CreateJwtRequest {
  @IsEmail()
  @IsDefined()
  @ApiProperty({
    description: 'Email of an existing user',
    example: 'my@email.com',
  })
  email: string;

  @IsString()
  @MinLength(1)
  @ApiProperty({
    description: 'Password of an existing user',
    example: 'password',
  })
  password: string;
}

/** Request body used to create a new jwt */
export class RefreshJwtRequest {
  @IsString()
  @MinLength(1)
  @ApiProperty({
    description: 'A refresh JWT',
    externalDocs: {
      url: 'https://tools.ietf.org/html/rfc7519',
      description: 'RFC 7519',
    },
    example:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI3ZDUxMWY0Ny1kYzU4LTQwMmYtYWM3Yy01ZjliZTc0NzhjNmMiLCJpYXQiOjE2MDY2OTY2MDYsInJvbGUiOiJhZG1pbiIsImV4cCI6MTYwNzMwMTQwNn0.hZettP2Iz6vTz62yaRyxYvw1llmRq8usOMJz5u--mf4',
  })
  refreshToken: string;
}

export class ForgotPasswordRequest {
  @IsEmail()
  @IsDefined()
  @ApiProperty({
    description: 'Email of an existing user',
    example: 'my@email.com',
  })
  email: string;
}

export class ResetPasswordRequest {
  /**
   * Password complexity :
   * - Minimum length 8 chars,
   * - minimum 3 of the 4 categories : lowercase, uppercase, number, special chars
   */
  @IsString()
  @MinLength(8)
  @Matches(passwordStrengthRegex, {
    message:
      'Password must be at least 8 characters long, and contain at least 3 of the 4 categories : lowercase, uppercase, number, special chars',
  })
  @ApiProperty({
    description: 'The new password',
    example: 'password',
  })
  password: string;
}

/** Response body after a successful authentication */
export class CreateJwtResponse {
  @ApiProperty({
    externalDocs: {
      url: 'https://tools.ietf.org/html/rfc7519',
      description: 'RFC 7519',
    },
    description:
      'Signed access token JWT. Carries the necessary information to access API ressources directly. Short lived.',
    example:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI3ZDUxMWY0Ny1kYzU4LTQwMmYtYWM3Yy01ZjliZTc0NzhjNmMiLCJpYXQiOjE2MDczMDM2NzUsInJvbGUiOiJhZG1pbiIsImV4cCI6MTYwNzMwMzczNX0.B3v7UBxkgwjhIBrqNOH-j1ACULJ0SVMQTkeRQQ_losA',
  })
  accessToken: string;

  @ApiProperty({
    description: 'Signed refresh token JWT. Carries the information necessary to get a new access token. Long-lived',
    externalDocs: {
      url: 'https://tools.ietf.org/html/rfc7519',
      description: 'RFC 7519',
    },
    example:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI3ZDUxMWY0Ny1kYzU4LTQwMmYtYWM3Yy01ZjliZTc0NzhjNmMiLCJpYXQiOjE2MDczMDM2NzUsInJvbGUiOiJhZG1pbiIsImV4cCI6MTYwNzMwMzczNX0.B3v7UBxkgwjhIBrqNOH-j1ACULJ0SVMQTkeRQQ_losA',
  })
  refreshToken: string;
}

/**
 * The JWT payload
 * @see {https://tools.ietf.org/html/rfc7519 | RFC7519}
 */
export class JsonWebToken {
  /** (subject): Subject of the JWT (the user) */
  sub: string;

  /** (expiration time): Time after which the JWT expires */
  exp: number;

  /** (issued at time): Time at which the JWT was issued; can be used to determine age of the JWT */
  iat: number;

  /** User role */
  role: PatientRole | StaffRole;
}
