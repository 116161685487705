import { ApiProperty, IsBoolean, IsIn, IsUUID, OmitType, ValidateIf } from './decorators-nest';

export enum DesireToEatMoment {
  EVENING = 0,
  ANYTIME = 1,
}

/**
 * Payload to create a Stress Survey
 * @todo: translate each description in english
 */
export class CreateStressSurveyDto {
  @ApiProperty({
    description: 'related patient',
    format: 'uuid',
  })
  @IsUUID()
  patient: string;

  @ApiProperty()
  @IsBoolean()
  STRESSED: boolean;

  @ApiProperty()
  @IsBoolean()
  ANXIOUS: boolean;

  @ApiProperty()
  @IsBoolean()
  STRESSED_ON_WAKING: boolean;

  @ApiProperty()
  @IsBoolean()
  STRESS_PERSONNAL_OR_JOB: boolean;

  @ApiProperty()
  @IsBoolean()
  STRESSFUL_EVENT_PROFESSIONAL: boolean;

  @ApiProperty()
  @IsBoolean()
  STRESSFUL_EVENT_MARRIAGE: boolean;

  @ApiProperty()
  @IsBoolean()
  STRESSFUL_EVENT_DIVORCE: boolean;

  @ApiProperty()
  @IsBoolean()
  STRESSFUL_EVENT_BEREAVEMENT: boolean;

  @ApiProperty()
  @IsBoolean()
  STRESSFUL_EVENT_HARASSMENT: boolean;

  @ApiProperty()
  @IsBoolean()
  STRESSFUL_EVENT_MOVE: boolean;

  @ApiProperty()
  @IsBoolean()
  STRESSFUL_EVENT_FINANCIAL: boolean;

  @ApiProperty()
  @IsBoolean()
  STRESSFUL_EVENT_SURGERY: boolean;

  @ApiProperty()
  @IsBoolean()
  STRESSFUL_EVENT_ACCIDENT: boolean;

  @ApiProperty()
  @IsBoolean()
  DESIRE_TO_EAT: boolean;

  @ApiProperty({
    description: '0 means evening, 1 means anytime',
    enum: [0, 1],
  })
  @IsIn([0, 1])
  @ValidateIf((o: CreateStressSurveyDto) => o.DESIRE_TO_EAT === true)
  DESIRE_TO_EAT_MOMENT: 0 | 1;

  @ApiProperty()
  @IsBoolean()
  @ValidateIf((o: CreateStressSurveyDto) => o.DESIRE_TO_EAT === true)
  SNACKING: boolean;

  @ApiProperty()
  @IsBoolean()
  @ValidateIf((o: CreateStressSurveyDto) => o.DESIRE_TO_EAT === true)
  ALCOHOL_RELAXED: boolean;

  @ApiProperty()
  @IsBoolean()
  MED_SPASMOPHILIA: boolean;

  @ApiProperty()
  @IsBoolean()
  MED_TINNITUS: boolean;

  @ApiProperty()
  @IsBoolean()
  MED_PALPITATIONS: boolean;

  @ApiProperty()
  @IsBoolean()
  MED_HAIR_LOSS: boolean;

  @ApiProperty()
  @IsBoolean()
  MED_CRAMPS: boolean;
}

/**
 * Representation of a stress survey
 */
export class StressSurveyDto extends OmitType(CreateStressSurveyDto, ['patient'] as const) {
  @ApiProperty({
    type: 'string',
    format: 'uuid',
  })
  id: string;

  @ApiProperty({
    format: 'date-time',
  })
  createdDate: string;

  @ApiProperty({
    format: 'date-time',
  })
  updatedDate: string;
}

/**
 * Payload to create a Follow Up Stress Survey
 */
export class CreateFollowUpStressSurveyDto extends CreateStressSurveyDto {
  @ApiProperty()
  @IsBoolean()
  CHANGE_STATE_STRESS: boolean;

  @ValidateIf((o: CreateFollowUpStressSurveyDto) => o.CHANGE_STATE_STRESS === true)
  STRESSED: boolean;

  @ValidateIf((o: CreateFollowUpStressSurveyDto) => o.CHANGE_STATE_STRESS === true)
  ANXIOUS: boolean;

  @ValidateIf((o: CreateFollowUpStressSurveyDto) => o.CHANGE_STATE_STRESS === true)
  STRESSED_ON_WAKING: boolean;

  @ValidateIf((o: CreateFollowUpStressSurveyDto) => o.CHANGE_STATE_STRESS === true)
  STRESS_PERSONNAL_OR_JOB: boolean;

  @ValidateIf((o: CreateFollowUpStressSurveyDto) => o.CHANGE_STATE_STRESS === true)
  STRESSFUL_EVENT_PROFESSIONAL: boolean;

  @ValidateIf((o: CreateFollowUpStressSurveyDto) => o.CHANGE_STATE_STRESS === true)
  STRESSFUL_EVENT_MARRIAGE: boolean;

  @ValidateIf((o: CreateFollowUpStressSurveyDto) => o.CHANGE_STATE_STRESS === true)
  STRESSFUL_EVENT_DIVORCE: boolean;

  @ValidateIf((o: CreateFollowUpStressSurveyDto) => o.CHANGE_STATE_STRESS === true)
  STRESSFUL_EVENT_BEREAVEMENT: boolean;

  @ValidateIf((o: CreateFollowUpStressSurveyDto) => o.CHANGE_STATE_STRESS === true)
  STRESSFUL_EVENT_HARASSMENT: boolean;

  @ValidateIf((o: CreateFollowUpStressSurveyDto) => o.CHANGE_STATE_STRESS === true)
  STRESSFUL_EVENT_MOVE: boolean;

  @ValidateIf((o: CreateFollowUpStressSurveyDto) => o.CHANGE_STATE_STRESS === true)
  STRESSFUL_EVENT_FINANCIAL: boolean;

  @ValidateIf((o: CreateFollowUpStressSurveyDto) => o.CHANGE_STATE_STRESS === true)
  STRESSFUL_EVENT_SURGERY: boolean;

  @ValidateIf((o: CreateFollowUpStressSurveyDto) => o.CHANGE_STATE_STRESS === true)
  STRESSFUL_EVENT_ACCIDENT: boolean;

  @ValidateIf((o: CreateFollowUpStressSurveyDto) => o.CHANGE_STATE_STRESS === true)
  DESIRE_TO_EAT: boolean;

  @ValidateIf((o: CreateFollowUpStressSurveyDto) => o.CHANGE_STATE_STRESS === true)
  DESIRE_TO_EAT_MOMENT: 0 | 1;

  @ValidateIf((o: CreateFollowUpStressSurveyDto) => o.CHANGE_STATE_STRESS === true)
  SNACKING: boolean;

  @ValidateIf((o: CreateFollowUpStressSurveyDto) => o.CHANGE_STATE_STRESS === true)
  ALCOHOL_RELAXED: boolean;

  @ValidateIf((o: CreateFollowUpStressSurveyDto) => o.CHANGE_STATE_STRESS === true)
  MED_SPASMOPHILIA: boolean;

  @ValidateIf((o: CreateFollowUpStressSurveyDto) => o.CHANGE_STATE_STRESS === true)
  MED_TINNITUS: boolean;

  @ValidateIf((o: CreateFollowUpStressSurveyDto) => o.CHANGE_STATE_STRESS === true)
  MED_PALPITATIONS: boolean;

  @ValidateIf((o: CreateFollowUpStressSurveyDto) => o.CHANGE_STATE_STRESS === true)
  MED_HAIR_LOSS: boolean;

  @ValidateIf((o: CreateFollowUpStressSurveyDto) => o.CHANGE_STATE_STRESS === true)
  MED_CRAMPS: boolean;
}

/**
 * Payload to update a Sign Up Stress Survey
 */
export class UpdateStressSurveyDto extends OmitType(CreateStressSurveyDto, ['patient'] as const) {}
