import {
  ApiProperty,
  ApiPropertyOptional,
  IsBoolean,
  IsDateString,
  IsDefined,
  IsEnum,
  IsNumber,
  IsOptional,
  IsString,
  PartialType,
  PickType,
} from './decorators-nest';

/**
 * Enum to specify to background processor type of element updated
 * that starts recomputation of nutritional values
 */
export enum UpdatedType {
  INGREDIENT = 'ingredient',
  UNIT = 'unit',
  RECIPE = 'recipe',
}

/**
 * Enum to specify to background processor target to update
 */
export enum UpdateTarget {
  SEASONS = 'seasons',
  NUTRIENTS = 'nutrients',
  TAGS = 'tags',
}

/**
 * Possible sort field when querying recipe list
 */
export enum RecipeListSortField {
  RECIPE_NAME = 'recipe-name',
  RECIPE_PRICE = 'recipe-price',
  RECIPE_TYPE = 'recipe-type',
  RECIPE_STATUS = 'recipe-status',
  CALORIES_PER_SERVING = 'calories-per-serving',
}

/**
 * Possible sort field when querying ingredient list
 */
export enum IngredientListSortField {
  INGREDIENT_NAME = 'ingredient-name',
  INGREDIENT_STATUS = 'ingredient-status',
  NB_RECIPES = 'nb-recipes',
  SUPERMARKET_AISLE_NAME = 'supermarket-aisle-name',
}

/**
 * FOOD REFERENCE SOURCE: CIQUAL or CNF
 */
export enum FoodReferenceSource {
  CIQUAL = 'ciqual',
  CNF = 'cnf',
}

/**
 * Possible sort field when querying unit family list
 */
export enum UnitFamilyListSortField {
  UNIT_FAMILY_NAME = 'unit-family-name',
  NB_UNITS = 'nb-units',
  NB_INGREDIENTS = 'nb-ingredients',
}

/* Recipe enums and interfaces */
export enum TagName {
  isFish = 'Poisson',
  isPork = 'Porc',
  isDairyProduct = 'Produit laitier',
  isFeculent = 'Féculent',
  isLegumineuse = 'Légumineuse',
  isCheese = 'Fromage',
  isVegetable = 'Légume',
  isFruit = 'Fruit',
  isRedMeat = 'Viande rouge',
  isCharcuterie = 'Charcuterie',
  isSpice = 'Épices & aromates',
  isVegetalFat = 'Matière grasse végétale',
  isVegetalMilk = 'Boisson végétale',
  isSauce = 'Sauce',
  isAnimalFat = 'Matière grasse animale',
  isSeafood = 'Fruits de mer',
  containsGluten = 'Gluten',
  containsLactose = 'Lactose',

  isFatFish = 'Petits poissons gras',
  isEgg = 'Oeuf',
  isOilseeds = 'Oléagineux',
  isDrink = 'Boisson',
  isConserve = 'Conserve',
  isPoultry = 'Volaille',
  isOffal = 'Abats',
  isProcessed = 'Transformés',
  other = 'Autre',

  // For recipes only
  isCaloricDessert = 'Dessert calorique',
  isSoup = 'Soupe',
  isVegetarian = 'Végétarien',
  isPescoVegetarian = 'Pesco-végétarien',
  isVegan = 'Vegan',

  // New ingredients tags cf fablife/weight#201
  isApricot = 'Abricot',
  isBeef = 'Bœuf',
  isDuck = 'Canard',
  isChicken = 'Poulet',
  isGarlic = 'Ail',
  isSeaweed = 'Algues',
  isAlmonds = 'Amandes',
  isDill = 'Aneth',
  isArtichoke = 'Artichaut',
  isAsparagus = 'Asperges',
  isEggplant = 'Aubergines',
  isAvocado = 'Avocat',
  isBasil = 'Basilic',
  isCinnamon = 'Cannelle',
  isBeets = 'Betteraves',
  isPeanuts = 'Cacahuètes',
  isButter = 'Beurre',
  isLeek = 'Poireau',
  isWheat = 'Blé',
  isBlueCheese = 'Fromages bleus',
  isOats = 'Avoine',
  isSoybeans = 'Soja',
  isSquash = 'Courges',
  isCardamom = 'Cardamome',
  isCarrots = 'Carottes',
  isMushrooms = 'Champignons',
  isChervil = 'Cerfeuil',
  isNuts = 'Noix',
  isCrab = 'Crabe',
  isGoatCheese = 'Fromage de chèvre',
  isChocolate = 'Chocolat',
  isCabbage = 'Choux',
  isChives = 'Ciboulette',
  isLemonGrass = 'Citronelle',
  isLemon = 'Citron',
  isCompote = 'Compote',
  isTomatoes = 'Tomates',
  isPasta = 'Pâtes',
  isJam = 'Confiture',
  isCoriander = 'Coriandre',
  isVeal = 'Veau',
  isStrawberry = 'Fraise',
  isRaspberry = 'Framboise',
  isRedBerries = 'Fruits rouges',
  isCoconut = 'Noix de coco',
  isRabbit = 'Lapin',
  isCumin = 'Cumin',
  isTurmeric = 'Curcuma',
  isBeans = 'Haricots',
  isTurkey = 'Dinde',
  isLamb = 'Agneau',
  isSpinach = 'Épinards',
  isTarragon = 'Estragon',
  isLaurel = 'Laurier',
  isFigs = 'Figues',
  isAnchovies = 'Anchois',
  isMackerel = 'Maquereaux',
  isRedMullet = 'Rouget',
  isFlageolets = 'Flageolets',
  isCottageCheese = 'Fromage blanc',
  isGinger = 'Gingembre',
  isSesame = 'Sésame',
  isVanilla = 'Vanille',
  isSeeds = 'Graines',
  isSalt = 'Sel',
  isDryBeans = 'Haricots secs',
  isChickpeas = 'Pois chiches',
  isHazelnuts = 'Noisettes',
  isApple = 'Pomme',
  isKiwi = 'Kiwi',
  isMilk = 'Lait',
  isSalads = 'Salades',
  isLentils = 'Lentilles',
  isCorn = 'Maïs',
  isMint = 'Menthe',
  isEggs = 'Œufs',
  isOnions = 'Oignons',
  isOlives = 'Olives',
  isCitrus = 'Agrumes',
  isCurry = 'Curry',
  isParsley = 'Persil',
  isChilli = 'Piment',
  isPistachios = 'Pistaches',
  isPeppers = 'Poivron',
  isPlums = 'Prunes',
  isQuinoa = 'Quinoa',
  isGrapes = 'Raisin',
  isRice = 'Riz',
  isRosemary = 'Romarin',
  isSausages = 'Saucisses',
  isSage = 'Sauge',
  isSalmon = 'Saumon',
  isSugars = 'Sucres',
  isTuna = 'Thon',
  isThyme = 'Thym',
  isVinegars = 'Vinaigres',
  isYogurt = 'Yaourt',
  isHoney = 'Miel',
}
export enum SeasonMonth {
  january = 'january',
  february = 'february',
  march = 'march',
  april = 'april',
  may = 'may',
  june = 'june',
  july = 'july',
  august = 'august',
  september = 'september',
  october = 'october',
  november = 'november',
  december = 'december',
}
export enum RecipeType {
  breakfast = 'breakfast',
  minute_meal = 'minute_meal',
  appetizer_starter = 'appetizer_starter',
  snack = 'snack',
  dessert = 'dessert',
  dish = 'dish',
}
export enum RecipeDifficulty {
  easy = 'easy',
  medium = 'medium',
  hard = 'hard',
}
export enum RecipeStatus {
  TO_PUBLISH = 'TO_PUBLISH',
  TO_VALIDATE = 'TO_VALIDATE',
  DRAFT = 'DRAFT',
}
export enum IngredientStatus {
  TO_PUBLISH = 'TO_PUBLISH',
  DRAFT = 'DRAFT',
}
/**
 * All timings are expressed in milliseconds
 */
export class RecipeTimings {
  preparation: number;
  cooking: number;
  resting: number;
}
export enum NutrientBalance {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

/**
 * Dislike reason
 */
export enum DislikeReason {
  TOO_FREQUENT = 'too-frequent',
  BADLY_WRITTEN = 'badly-written',
}

export enum RecipePrice {
  low = 'low',
  medium = 'medium',
  high = 'high',
}

/* Tag related Dto */
export class TagDto {
  @ApiProperty({ description: 'Tag id' })
  @IsNumber()
  id: number;

  @ApiProperty({ description: 'Tag name' })
  @IsString()
  name: string;

  @ApiProperty({ description: 'created at timestamp' })
  @IsDateString()
  @IsOptional()
  createdDate: Date;

  @ApiProperty({ description: 'updated at timestamp' })
  @IsDateString()
  @IsOptional()
  updatedDate: Date;

  @ApiProperty({ description: 'version number' })
  @IsOptional()
  version: number;
}
export class CreateTagDto {
  @ApiProperty({ description: 'Tag name' })
  @IsString()
  name: string;
}

/* SupermarketAisle related Dto */
export class SupermarketAisleDto {
  @ApiProperty({ description: 'Supermarket aisle id' })
  @IsNumber()
  id: number;

  @ApiProperty({ description: 'Supermarket aisle name' })
  @IsString()
  name: string;

  @ApiProperty({
    description: 'List of ingredients linked to this supermarket isle',
  })
  @IsOptional()
  ingredients: IngredientDto[];

  @ApiProperty({ description: 'created at timestamp' })
  @IsDateString()
  @IsOptional()
  createdDate: Date;

  @ApiProperty({ description: 'updated at timestamp' })
  @IsDateString()
  @IsOptional()
  updatedDate: Date;

  @ApiProperty({ description: 'version number' })
  @IsOptional()
  version: number;
}
export class CreateSupermarketAisleDto {
  @ApiProperty({ description: 'Supermarket aisle name' })
  @IsString()
  name: string;
}

/* UnitFamily related Dto */
export class UnitFamilyDto {
  @ApiProperty({ description: 'UnitFamily id' })
  @IsNumber()
  id: number;

  @ApiProperty({ description: 'UnitFamily name' })
  @IsString()
  name: string;

  @ApiProperty({ description: 'List of units related' })
  @IsOptional()
  units: UnitDto[];

  @ApiProperty({ description: 'List of ingredients related' })
  @IsOptional()
  ingredients: IngredientDto[];

  @ApiProperty({ description: 'created at timestamp' })
  @IsDateString()
  @IsOptional()
  createdDate: Date;

  @ApiProperty({ description: 'updated at timestamp' })
  @IsDateString()
  @IsOptional()
  updatedDate: Date;

  @ApiProperty({ description: 'version number' })
  @IsOptional()
  version: number;

  @ApiProperty({ description: 'Number of units that use this unit family', required: false })
  @IsNumber()
  @IsOptional()
  numberOfUnits?: number;

  @ApiProperty({ description: 'Number of ingredients that use this unit family', required: false })
  @IsNumber()
  @IsOptional()
  numberOfIngredients?: number;
}
export class CreateUnitFamilyDto {
  @ApiProperty({ description: 'UnitFamily name' })
  @IsString()
  name: string;

  @ApiProperty({ description: 'UnitFamily slug', required: false })
  @IsString()
  @IsOptional()
  slug?: string;
}

export class UpdateUnitFamilyDto extends PartialType(CreateUnitFamilyDto) {}

/* Unit related Dto */
export class UnitDto {
  @ApiProperty({ description: 'Unit id' })
  @IsNumber()
  id: number;

  @ApiProperty({ description: 'Unit Name' })
  @IsString()
  name: string;

  @ApiProperty({ description: 'Unit grams' })
  @IsNumber()
  grams: number;

  @ApiProperty({ description: 'Id of related unit family' })
  @IsNumber()
  unitFamilyId: number;

  @ApiProperty({ description: 'Is the unit the reference unit for its unit family' })
  @IsBoolean()
  public isReferenceUnit: boolean;

  @ApiProperty({ description: 'Unit converted to reference unit' })
  @IsNumber()
  public conversionToReferenceUnit: number;

  @ApiProperty({ description: 'Related recipe ingredients', required: false })
  @IsOptional()
  recipeIngredients?: RecipeIngredientDto[];

  @ApiProperty({ description: 'created at timestamp' })
  @IsDateString()
  @IsOptional()
  createdDate: Date;

  @ApiProperty({ description: 'updated at timestamp' })
  @IsDateString()
  @IsOptional()
  updatedDate: Date;

  @ApiProperty({ description: 'version number' })
  @IsOptional()
  version: number;
}
export class CreateUnitDto {
  @ApiProperty({ description: 'Unit Name' })
  @IsString()
  name: string;

  @ApiProperty({ description: 'Unit grams' })
  @IsNumber()
  grams: number;

  @ApiProperty({ description: 'Id of related unit family' })
  @IsNumber()
  unitFamilyId: number;

  @ApiProperty({ description: 'Is the unit the reference unit for its unit family', required: false })
  @IsBoolean()
  @IsOptional()
  public isReferenceUnit?: boolean;

  @ApiProperty({ description: 'Unit converted to reference unit', required: false })
  @IsNumber()
  @IsOptional()
  public conversionToReferenceUnit?: number;
}
export class UpdateUnitDto extends PartialType(CreateUnitDto) {}

/* Nutrient related Dto */
export class NutrientDto {
  @ApiProperty({ description: 'Nutrient id' })
  @IsNumber()
  id: number;

  @ApiProperty({ description: 'Nutrient name' })
  @IsString()
  name: string;

  @ApiProperty({ description: 'True if nutrient is a composite' })
  @IsBoolean()
  composite: boolean;

  @ApiProperty({
    description: 'Daily, weekly, monthly',
    type: 'enum',
    enum: NutrientBalance,
  })
  @IsEnum(NutrientBalance)
  balance: NutrientBalance;

  @ApiProperty({ description: 'Nutrient coefficient' })
  @IsNumber()
  coefficient: number;

  @ApiProperty({ description: 'Nutrient VNR threshold' })
  @IsNumber()
  vnrThreshold: number;

  @ApiProperty({ description: 'created at timestamp' })
  @IsDateString()
  @IsOptional()
  createdDate: Date;

  @ApiProperty({ description: 'updated at timestamp' })
  @IsDateString()
  @IsOptional()
  updatedDate: Date;

  @ApiProperty({ description: 'version number' })
  @IsOptional()
  version: number;
}
export class CreateNutrientDto {
  @ApiProperty({ description: 'Nutrient name' })
  @IsString()
  name: string;

  @ApiProperty({ description: 'True if nutrient is a composite' })
  @IsBoolean()
  composite: boolean;

  @ApiProperty({
    description: 'Daily, weekly, monthly',
    type: 'enum',
    enum: NutrientBalance,
  })
  @IsEnum(NutrientBalance)
  balance: NutrientBalance;

  @ApiProperty({ description: 'Nutrient coefficient' })
  @IsNumber()
  coefficient: number;

  @ApiProperty({ description: 'Nutrient VNR threshold' })
  @IsNumber()
  vnrThreshold: number;
}
export class UpdateNutrientDto extends PartialType(CreateNutrientDto) {}

/* Season related Dto */
export class SeasonDto {
  @ApiProperty({ description: 'Season id' })
  @IsNumber()
  id: number;

  @ApiProperty({ description: 'Month of the season' })
  @IsEnum(SeasonMonth)
  month: SeasonMonth;

  @ApiProperty({ description: 'created at timestamp' })
  @IsDateString()
  @IsOptional()
  createdDate: Date;

  @ApiProperty({ description: 'updated at timestamp' })
  @IsDateString()
  @IsOptional()
  updatedDate: Date;

  @ApiProperty({ description: 'version number' })
  @IsOptional()
  version: number;
}
export class CreateSeasonDto {
  @ApiProperty({ description: 'Month of the season' })
  @IsEnum(SeasonMonth)
  month: SeasonMonth;
}
export class UpdateSeasonDto extends PartialType(CreateSeasonDto) {}

/* Ingredient related Dto */
export class IngredientDto {
  @ApiProperty({ description: 'Ingredient id' })
  @IsNumber()
  id: number;

  @ApiProperty({ description: 'Related Unit Family Id' })
  @IsNumber()
  unitFamilyId: number;

  @ApiProperty({ description: 'Ingredient name' })
  @IsString()
  name: string;

  @ApiProperty({ description: 'Related Supermarket Aisle id' })
  @IsNumber()
  supermarketAisleId: number;

  @ApiProperty({ description: 'Ingredient image url' })
  @IsString()
  imageUrl: string;

  @ApiProperty({ description: 'Ingredient status', type: 'enum', enum: IngredientStatus })
  @IsEnum(IngredientStatus)
  status: IngredientStatus;

  @ApiProperty({ description: 'Ingredient needs to be reviewed' })
  @IsBoolean()
  inReview: boolean;

  @ApiProperty({ description: 'Related raw nutrients' })
  @IsOptional()
  rawNutrients: IngredientNutrientDto[];

  @ApiProperty({ description: 'Related cooked nutrients' })
  @IsOptional()
  cookedNutrients: IngredientNutrientDto[];

  @ApiProperty({ description: 'Related seasons' })
  @IsOptional()
  seasons: SeasonDto[];

  @ApiProperty({ description: 'Related tags' })
  @IsOptional()
  tags: TagDto[];

  @ApiProperty({ description: 'Related recipe ingredients', required: false })
  @IsOptional()
  recipeIngredients?: RecipeIngredientDto[];

  @ApiProperty({ description: 'Raw mapping' })
  @IsString()
  rawMapping: string;

  @ApiProperty({ description: 'Cooked mapping' })
  @IsString()
  cookedMapping: string;

  @ApiProperty({ description: 'Cooking coeff' })
  @IsNumber()
  cookingCoeff: number;

  @ApiProperty({ description: 'Raw calories per 100g' })
  @IsNumber()
  rawCaloriesPer100g: number;

  @ApiProperty({ description: 'Cooked calories per 100g' })
  @IsNumber()
  cookedCaloriesPer100g: number;

  @ApiProperty({ description: 'created at timestamp' })
  @IsDateString()
  @IsOptional()
  createdDate: Date;

  @ApiProperty({ description: 'updated at timestamp' })
  @IsDateString()
  @IsOptional()
  updatedDate: Date;

  @ApiProperty({ description: 'version number' })
  @IsOptional()
  version: number;

  @ApiProperty({ description: 'CIQUAL or CNF', required: false, type: 'enum', enum: FoodReferenceSource })
  @IsEnum(FoodReferenceSource)
  @IsOptional()
  foodSource?: FoodReferenceSource;

  @ApiProperty({ description: 'food name of the ciqual or cnf food specified in rawMapping', required: false })
  @IsString()
  @IsOptional()
  referenceFoodName?: string;

  @ApiProperty({ description: 'Related Supermarket Aisle', required: false })
  @IsOptional()
  supermarketAisle?: SupermarketAisleDto;

  @ApiProperty({ description: 'Number of recipes that use this ingredient', required: false })
  @IsNumber()
  @IsOptional()
  numberOfRecipes?: number;
}
export class CreateIngredientDto {
  @ApiProperty({ description: 'Ingredient name' })
  @IsString()
  name: string;

  @ApiProperty({ description: 'Related Unit Family' })
  @IsNumber()
  @IsOptional()
  unitFamilyId?: number;

  @ApiProperty({ description: 'Related Supermarket Aisle' })
  @IsNumber()
  @IsOptional()
  supermarketAisleId?: number;

  @ApiProperty({ description: 'Ingredient image url' })
  @IsString()
  imageUrl: string;

  @ApiProperty({ description: 'Ingredient status', type: 'enum', enum: IngredientStatus })
  @IsEnum(IngredientStatus)
  status: IngredientStatus;

  @ApiProperty({ description: 'Ingredient needs to be reviewed' })
  @IsBoolean()
  inReview: boolean;

  @ApiProperty({ description: 'Related seasons months' })
  @IsOptional()
  seasonMonths: SeasonMonth[];

  @ApiProperty({ description: 'Related tags ids' })
  @IsOptional()
  tagsIds: number[];

  @ApiPropertyOptional({ description: 'Raw mapping' })
  @IsOptional()
  rawMapping: string;

  @ApiPropertyOptional({ description: 'Cooked mapping' })
  @IsOptional()
  cookedMapping: string;

  @ApiProperty({ description: 'Cooking coeff' })
  @IsNumber()
  cookingCoeff: number;

  @ApiProperty({ description: 'Raw calories per 100g' })
  @IsNumber()
  rawCaloriesPer100g: number;

  @ApiProperty({ description: 'Cooked calories per 100g' })
  @IsNumber()
  cookedCaloriesPer100g: number;
}
export class UpdateIngredientDto extends PartialType(CreateIngredientDto) {}

/* IngredientNutrient related Dto */
export class IngredientNutrientDto {
  id: number;
  rawIngredient: IngredientDto;
  cookedIngredient: IngredientDto;
  nutrient: NutrientDto;
  grams: number;

  @ApiProperty({ description: 'created at timestamp' })
  @IsDateString()
  @IsOptional()
  createdDate: Date;

  @ApiProperty({ description: 'updated at timestamp' })
  @IsDateString()
  @IsOptional()
  updatedDate: Date;

  @ApiProperty({ description: 'version number' })
  @IsOptional()
  version: number;
}
export class CreateIngredientNutrientsDto {
  nutrientId: number;
  grams: number;
}
export class UpdateIngredientNutrientsDto {
  ingredientId: number;
  rawNutrients: CreateIngredientNutrientsDto[];
  cookedNutrients: CreateIngredientNutrientsDto[];
}

/* RecipeNutrient related Dto */
export class RecipeNutrientDto {
  recipe: RecipeDto;

  @ApiProperty({ description: 'nutrient in the recipe', type: NutrientDto })
  @IsDefined()
  nutrient: NutrientDto;

  @ApiProperty({ description: 'number of grams of the nutrient in the recipe' })
  @IsNumber()
  grams: number;

  @ApiProperty({ description: 'created at timestamp' })
  @IsDateString()
  @IsOptional()
  createdDate: Date;

  @ApiProperty({ description: 'updated at timestamp' })
  @IsDateString()
  @IsOptional()
  updatedDate: Date;

  @ApiProperty({ description: 'version number' })
  @IsOptional()
  version: number;
}
export class CreateRecipeNutrientsDto {
  nutrientId: number;
  grams: number;
}
export class UpdateRecipeNutrientsDto {
  recipeId: number;
  nutrients: CreateRecipeNutrientsDto[];
}

/* RecipeIngredient related Dto */
export class RecipeIngredientDto {
  id: number;
  recipe: RecipeDto;

  @ApiProperty({
    description: 'Ingredient in the recipe',
    type: IngredientDto,
  })
  @IsDefined()
  ingredient: IngredientDto;

  @IsBoolean()
  @ApiProperty({ description: 'Boolean for whether ingredient is cooked' })
  isCooked: boolean;

  @IsNumber()
  @ApiProperty({ description: 'Quantity of ingredient used in recipe' })
  qty: number;

  @IsDefined()
  @ApiProperty({ description: 'Unit in the recipe', type: UnitDto })
  unit: UnitDto;

  @ApiProperty({ description: 'created at timestamp' })
  @IsDateString()
  @IsOptional()
  createdDate: Date;

  @ApiProperty({ description: 'updated at timestamp' })
  @IsDateString()
  @IsOptional()
  updatedDate: Date;

  @ApiProperty({ description: 'version number' })
  @IsOptional()
  version: number;
}
export class CreateRecipeIngredientDto {
  @IsNumber()
  @ApiProperty({ description: 'Id of ingredient used in recipe' })
  ingredientId: number;

  @IsBoolean()
  @ApiProperty({ description: 'Boolean for whether ingredient is cooked' })
  isCooked: boolean;

  @IsNumber()
  @ApiProperty({ description: 'Quantity of ingredient used in recipe' })
  qty: number;

  @IsNumber()
  @ApiProperty({ description: 'Id of unit used for related ingredient' })
  unitId: number;
}

/* Recipe related Dto */
export class RecipeDto {
  @IsNumber()
  @ApiProperty({
    description: 'Recipe id',
  })
  id: number;

  @IsString()
  @ApiProperty({
    description: 'Recipe name',
  })
  name: string;

  @IsNumber()
  @ApiProperty({ description: 'Number of servings' })
  servings: number;

  @IsEnum(RecipeDifficulty)
  @ApiProperty({
    type: 'enum',
    enum: RecipeDifficulty,
  })
  difficulty: RecipeDifficulty;

  @IsEnum(RecipeStatus)
  @ApiProperty({ type: 'enum', enum: RecipeStatus })
  status: RecipeStatus;

  @IsBoolean()
  @ApiProperty({ description: 'True if recipe was soft deleted cf #1204' })
  isArchived: boolean;

  @IsDefined()
  @ApiProperty({
    description: 'preparation, cooking and resting time',
  })
  timings: RecipeTimings;

  @IsDefined()
  @ApiProperty({
    type: CreateSeasonDto,
    isArray: true,
  })
  seasons: CreateSeasonDto[];

  @IsDefined()
  @ApiProperty({
    type: TagDto,
    isArray: true,
  })
  tags: TagDto[];

  @IsEnum(RecipeType)
  @ApiProperty({ enum: RecipeType, type: 'enum' })
  type: RecipeType;

  @IsEnum(RecipePrice)
  @ApiProperty({ enum: RecipePrice, type: 'enum' })
  price: RecipePrice;

  @IsString()
  @ApiProperty({ description: 'Description of the recipe steps' })
  steps: string;

  @IsString()
  @ApiProperty({ description: 'Url of the image for the recipe' })
  imageUrl: string;

  @IsDefined()
  @ApiProperty({
    description: 'List of ingredients used in the recipe',
    type: RecipeIngredientDto,
    isArray: true,
  })
  ingredients: RecipeIngredientDto[];

  @IsDefined()
  @ApiProperty({
    description: 'List of nutrients of the recipe',
    type: RecipeNutrientDto,
    isArray: true,
  })
  nutrients: RecipeNutrientDto[];

  @IsNumber()
  @ApiProperty({ description: 'Number of calories per serving' })
  caloriesPerServing: number;

  @ApiProperty({ description: 'created at timestamp' })
  @IsDateString()
  @IsOptional()
  createdDate: Date;

  @ApiProperty({ description: 'updated at timestamp' })
  @IsDateString()
  @IsOptional()
  updatedDate: Date;

  @ApiProperty({ description: 'version number' })
  @IsOptional()
  version: number;
}
export class CreateRecipeDto {
  @IsString()
  @ApiProperty({
    description: 'Recipe name',
  })
  name: string;

  @IsNumber()
  @ApiProperty({
    description: 'Number of servings',
  })
  servings: number;

  @IsEnum(RecipeDifficulty)
  @ApiProperty({
    type: 'enum',
    enum: RecipeDifficulty,
  })
  difficulty: RecipeDifficulty;

  @IsEnum(RecipeStatus)
  @ApiProperty({
    type: 'enum',
    enum: RecipeStatus,
  })
  status: RecipeStatus;

  @IsEnum(RecipePrice)
  @IsOptional()
  @ApiProperty({
    type: 'enum',
    enum: RecipePrice,
    required: false,
  })
  price?: RecipePrice;

  @IsBoolean()
  @IsOptional()
  @ApiProperty({ description: 'True if recipe was soft deleted cf #1204', required: false })
  isArchived?: boolean;

  @IsDefined()
  @ApiProperty({
    description: 'preparation, cooking and resting time',
  })
  timings: RecipeTimings;

  @IsEnum(RecipeType)
  @IsOptional()
  @ApiProperty({
    type: 'enum',
    enum: RecipeType,
    required: false,
  })
  type?: RecipeType;

  @IsString()
  @IsOptional()
  @ApiProperty({ description: 'Description of the recipe steps', required: false })
  steps?: string;

  @IsString()
  @IsOptional()
  @ApiProperty({ description: 'Url of the image for the recipe', required: false })
  imageUrl?: string;

  @IsDefined()
  @IsOptional()
  @ApiProperty({
    description: 'List of ingredients used in the recipe',
    type: CreateRecipeIngredientDto,
    isArray: true,
    required: false,
  })
  ingredients?: CreateRecipeIngredientDto[];

  @IsNumber()
  @IsOptional()
  @ApiProperty({ description: 'Number of calories per serving', required: false })
  caloriesPerServing?: number;
}
export class UpdateRecipeDto extends PartialType(CreateRecipeDto) {}

export class SubRecipeDto extends PickType(RecipeDto, ['id', 'name']) {}

export class SubRecipeImageDto extends PickType(RecipeDto, ['id', 'name', 'imageUrl']) {}

export class CreateDislikeReasonDto {
  @IsOptional()
  @IsEnum(DislikeReason)
  @ApiProperty({
    type: 'enum',
    enum: DislikeReason,
    required: false,
  })
  reason: DislikeReason;
}

export class RecipeDislikesDto {
  @IsBoolean()
  @ApiProperty({ description: 'recipe is disliked by patient' })
  dislike: boolean;

  @IsBoolean()
  @ApiProperty({ description: 'patient declared recipe as too_frequent' })
  too_frequent: boolean;

  @IsBoolean()
  @ApiProperty({ description: 'patient declared recipe as badly_written' })
  badly_written: boolean;
}
