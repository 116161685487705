import {
  ApiProperty,
  IsBoolean,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  OmitType,
  ValidateIf,
} from './decorators-nest';

/**
 * Payload to create a Reproductive Survey
 */
export class CreateReproductiveSurveyDto {
  @ApiProperty({
    description: 'related patient',
    format: 'uuid',
  })
  @IsUUID()
  patient: string;

  @ApiProperty({ required: true })
  @IsBoolean()
  MED_HISTORY_INFERTILITY_DIAGNOSED: boolean;

  @ApiProperty({ required: true })
  @IsBoolean()
  MED_HISTORY_ENDOMETRIOSIS: boolean;

  @ApiProperty({ required: true })
  @IsBoolean()
  MED_HISTORY_PCOS: boolean;

  @ApiProperty({ required: true })
  @IsBoolean()
  MED_HISTORY_OVARIAN_INSUFFICIENCY: boolean;

  @ApiProperty({ required: true })
  @IsBoolean()
  MED_HISTORY_OVULATION_ISSUES: boolean;

  @ApiProperty({ required: true })
  @IsBoolean()
  MED_HISTORY_HORMONAL_ISSUES: boolean;

  @ApiProperty({ required: true })
  @IsBoolean()
  GENETICS_MTHFR: boolean;

  @ApiProperty({ required: false })
  @IsBoolean()
  @ValidateIf((o: CreateReproductiveSurveyDto) => o.MED_HISTORY_INFERTILITY_DIAGNOSED === true)
  MED_TREATMENT_INFERTILITY: boolean;

  @ApiProperty({ required: false })
  @IsString()
  @ValidateIf((o: CreateReproductiveSurveyDto) => o.MED_HISTORY_INFERTILITY_DIAGNOSED === true)
  MED_TREATMENT_INFERTILITY_LABEL: string;

  @ApiProperty()
  @IsBoolean()
  BLOOD_HORMONES: boolean;

  @ApiProperty({ required: false, example: 2000 })
  @IsNumber()
  @ValidateIf((o: CreateReproductiveSurveyDto) => o.BLOOD_HORMONES === true)
  @IsOptional()
  BLOOD_TESTOSTERONE_TOTAL: number;

  @ApiProperty({ required: false, example: 2000 })
  @IsNumber()
  @ValidateIf((o: CreateReproductiveSurveyDto) => o.BLOOD_HORMONES === true)
  @IsOptional()
  BLOOD_TESTOSTERONE_FREE: number;

  @ApiProperty({ required: false })
  @IsNumber()
  @ValidateIf((o: CreateReproductiveSurveyDto) => o.BLOOD_HORMONES === true)
  @IsOptional()
  BLOOD_LH: number;

  @ApiProperty({ required: false, example: 200, minimum: 0, maximum: 1000 })
  @IsNumber()
  @ValidateIf((o: CreateReproductiveSurveyDto) => o.BLOOD_HORMONES === true)
  @IsOptional()
  BLOOD_ESTRADIOL: number;

  @ApiProperty({ required: false })
  @IsNumber()
  @ValidateIf((o: CreateReproductiveSurveyDto) => o.BLOOD_HORMONES === true)
  @IsOptional()
  BLOOD_PROGESTERONE: number;

  @ApiProperty({ required: false })
  @IsNumber()
  @ValidateIf((o: CreateReproductiveSurveyDto) => o.BLOOD_HORMONES === true)
  @IsOptional()
  BLOOD_FSH: number;

  @ApiProperty({ required: false })
  @IsNumber()
  @ValidateIf((o: CreateReproductiveSurveyDto) => o.BLOOD_HORMONES === true)
  @IsOptional()
  BLOOD_TSH: number;

  @ApiProperty({ required: false })
  @IsNumber()
  @ValidateIf((o: CreateReproductiveSurveyDto) => o.BLOOD_HORMONES === true)
  @IsOptional()
  BLOOD_AMH: number;
}

/**
 * Representation of a mood survey
 */
export class ReproductiveSurveyDto extends OmitType(CreateReproductiveSurveyDto, ['patient'] as const) {
  @ApiProperty({
    type: 'string',
    format: 'uuid',
  })
  id: string;

  @ApiProperty({
    format: 'date-time',
  })
  createdDate: string;

  @ApiProperty({
    format: 'date-time',
  })
  updatedDate: string;
}

/**
 * Payload to create a Follow Up Reproductive Survey
 */
export class CreateFollowUpReproductiveSurveyDto extends OmitType(CreateReproductiveSurveyDto, [
  'MED_HISTORY_INFERTILITY_DIAGNOSED',
  'MED_HISTORY_ENDOMETRIOSIS',
  'MED_HISTORY_PCOS',
  'MED_HISTORY_OVARIAN_INSUFFICIENCY',
  'MED_HISTORY_OVULATION_ISSUES',
  'MED_HISTORY_HORMONAL_ISSUES',
  'GENETICS_MTHFR',
  'MED_TREATMENT_INFERTILITY',
  'MED_TREATMENT_INFERTILITY_LABEL',
] as const) {}

/**
 * Payload to update a Sign Up Reproductive Survey
 */
export class UpdateReproductiveSurveyDto extends OmitType(CreateReproductiveSurveyDto, ['patient'] as const) {}
