import { IsDate, IsEnum, IsString } from 'class-validator';
import { ApiProperty } from './decorators-nest';

export enum FoodSupplementObservance {
  YES = 'yes',
  NO = 'no',
}

export enum MealPlanningObservance {
  YES = 'yes',
  NO = 'no',
  PARTIALLY = 'partially',
}

export enum ObservanceType {
  MEAL_PLANNING = 'meal-planning',
  FOOD_SUPPLEMENTS = 'food-supplements',
}

/**
 * Observance creation payload
 */
export class CreateObservanceDto {
  @ApiProperty({
    description: 'meal-planning or food-supplements',
    enum: ObservanceType,
    required: true,
  })
  @IsEnum(ObservanceType)
  public type: ObservanceType;

  @ApiProperty({
    description: 'Yes, No, Partially (if type === meal-planning)',
    enum: MealPlanningObservance || FoodSupplementObservance,
    required: true,
  })
  @IsEnum(MealPlanningObservance || FoodSupplementObservance)
  public value: MealPlanningObservance | FoodSupplementObservance;

  @ApiProperty({
    type: 'string',
    format: 'date',
    example: '2021-02-09',
  })
  @IsString()
  date: string;
}

/**
 * Observance record's date and value
 */
export class ObservanceRecordDto {
  @ApiProperty({
    type: 'string',
    format: 'date',
    example: '2021-02-09',
  })
  date: string;

  @ApiProperty({
    description: 'observance value',
    enum: MealPlanningObservance || FoodSupplementObservance,
    nullable: true,
  })
  value: MealPlanningObservance | FoodSupplementObservance;
}

/**
 * Patient observance records per observance type on a given date
 */
export class PatientObservanceDto {
  @ApiProperty({ description: 'date' })
  date: string;

  @ApiProperty({ nullable: true, enum: MealPlanningObservance })
  mealPlanning: MealPlanningObservance;

  @ApiProperty({ nullable: true, enum: FoodSupplementObservance })
  foodSupplements: FoodSupplementObservance;
}

/**
 * Patient observance records per observance type
 */
export class PatientObservanceListDto {
  @ApiProperty({ type: ObservanceRecordDto, isArray: true })
  mealPlanning: ObservanceRecordDto[];

  @ApiProperty({ type: ObservanceRecordDto, isArray: true })
  foodSupplements: ObservanceRecordDto[];
}
