import {
  ApiProperty,
  IsDateString,
  IsEnum,
  IsNotEmpty,
  IsString,
  IsUUID,
  OmitType,
  PartialType,
} from './decorators-nest';
import { PatientDto } from './patient.interface';
import { StaffDto } from './staff.interface';

/**
 * Enum for review types
 */
export enum CommentType {
  WELCOME = 'WELCOME',
  FOOD_SURVEY_RESULT_1 = 'FOOD_SURVEY_RESULT_1',
  FOLLOW_UP = 'FOLLOW_UP',
  OPTIONAL_FOLLOW_UP = 'OPTIONAL_FOLLOW_UP',
  NUTRITION_REPORT = 'NUTRITION_REPORT',
  FOOD_SURVEY_RESULT_2 = 'FOOD_SURVEY_RESULT_2',
  FINAL_ASSESSMENT = 'FINAL_ASSESSMENT',
  SPECIAL_CASE = 'SPECIAL_CASE',
}

/**
 * Bemum patient review
 */
export class ReviewDto {
  @ApiProperty({
    description: 'UUID of the review',
    type: 'string',
    format: 'uuid',
    example: '124ed8db-25e5-47d7-a4f3-b1d9fa934650',
  })
  id: string;

  @ApiProperty({
    description: 'Reviewee',
  })
  patient: PatientDto;

  @ApiProperty({
    description: 'Reviewer',
  })
  staff: StaffDto;

  @ApiProperty({
    description: 'The content of the review',
    example: '<p>Crucifix de sacrament de colon de sacristi.</p>',
  })
  content: string;

  @ApiProperty({
    description: 'Creation timestamp',
  })
  @IsDateString()
  createdDate: Date;

  @ApiProperty({
    description: 'Updation timestamp',
  })
  updatedDate: Date;

  @ApiProperty({
    description: 'Review category',
    enum: CommentType,
  })
  type: CommentType;
}

/**
 * Create review payload
 */

export class CreateReviewDto {
  @ApiProperty({
    description: 'Id of the reviewee',
    type: 'string',
    format: 'uuid',
    example: '124ed8db-25e5-47d7-a4f3-b1d9fa934650',
  })
  @IsUUID()
  @IsString()
  patientId: string;

  @ApiProperty({
    description: 'Actual review',
    example: '<p>Crucifix de sacrament de colon de sacristi</p>',
  })
  @IsString()
  @IsNotEmpty()
  content: string;

  @ApiProperty({
    description: 'Comment type',
    enum: CommentType,
    example: CommentType.WELCOME,
  })
  @IsEnum(CommentType)
  type: CommentType;
}

/**
 * Update review payload
 */
export class UpdateReviewDto extends PartialType(OmitType(CreateReviewDto, ['patientId'])) {}
