import { ApiProperty } from './decorators-nest';

export class AssessmentSurveyDto {
  @ApiProperty({ type: 'string', format: 'uuid' })
  public id: string;

  /** Buvez-vous des jus de fruits, sodas, boissons énergisantes et autres boissons sucrées ?*/
  @ApiProperty({ type: 'string' })
  public FREQUENCY_SODAS_1: string;

  /** Combien de thés ou de cafés buvez-vous par jour ?*/
  @ApiProperty({ type: 'string' })
  public FREQUENCY_COFFEE_1: string;

  /** Combien de boissons chaudes sucrées buvez-vous par jour ?*/
  @ApiProperty({ type: 'string' })
  public SUGARY_HOT_DRINKS_1: string;

  /** Consommez-vous des produits sucrés ?*/
  @ApiProperty({ type: 'string' })
  public FREQUENCY_SUGARY_FOODS_1: string;

  /** À quelle fréquence consommez-vous du fast-food et des produits frits ?*/
  @ApiProperty({ type: 'string' })
  public FREQUENCY_FAST_FOOD_1: string;

  /** À quelle fréquence consommez-vous des aliments transformés industriellement ?*/
  @ApiProperty({ type: 'string' })
  public FREQUENCY_PROCESSED_FOODS_1: string;

  /** À quelle fréquence êtes-vous amené·e à manger au restaurant ?*/
  @ApiProperty({ type: 'string' })
  public FREQUENCY_RESTAURANT_1: string;

  /** À quelle fréquence vous faites-vous livrer des plats ?*/
  @ApiProperty({ type: 'string' })
  public FREQUENCY_DELIVERY_FOOD_1: string;

  /** Avez-vous l'habitude de saler votre plat à la cuisson puis dans votre assiette?*/
  @ApiProperty({ type: 'string' })
  public SALTING_MEALS_1: string;

  /** Privilégiez-vous les produits complets ?*/
  @ApiProperty({ type: 'string' })
  public CEREAL_PRODUCTS_WHOLE_GRAIN_1: string;

  /** Privilégiez-vous les produits issus de l'agriculture biologique ?*/
  @ApiProperty({ type: 'string' })
  public ORGANIC_FOOD_1: string;

  /** Privilégiez-vous le fait maison ?*/
  @ApiProperty({ type: 'string' })
  public HOME_MADE_FOOD_1: string;

  /** A quel fréquence consomer vous de l'alcool ?*/
  @ApiProperty({ type: 'string' })
  public FREQUENCY_ALCOHOL_1: string;

  /** Combien de dose d'alcool consommez vous par semaine ?*/
  @ApiProperty({ type: 'string' })
  public ALCOHOL_QUANTITY_1: string;

  /** Si vous consommez plusieurs doses d'alcool par semaine, comment répartissez-vous votre consommation ?*/
  @ApiProperty({ type: 'string' })
  public ALCOHOL_DISTRIBUTION_1: string;

  /** Consommez-vous des cocktails ?*/
  @ApiProperty({ type: 'string' })
  public ALCOHOL_COCKTAIL_1: string;

  /** Faites vous la difference entre faim et envie de manger ?*/
  @ApiProperty({ type: 'string' })
  public DIFFERENCE_HUNGER_CRAVING_1: string;

  /** Avez-vous souvent envie ou besoin de manger au cours de la journée sans sensation de faim ?*/
  @ApiProperty({ type: 'string' })
  public FOOD_CRAVING_1: string;

  /** Si oui, vous arrive-t-il régulièrement de répondre à cette envie et de grignoter sans faim ?*/
  @ApiProperty({ type: 'string' })
  public FOOD_CRAVING_AND_SNACKING_1: string;

  /** Avez-vous tendance à continuer votre repas alors que vous n’avez plus faim ?*/
  @ApiProperty({ type: 'string' })
  public KEEPS_EATING_WITHOUT_HUNGER_1: string;

  /** Avez-vous tendance à vous resservir ?*/
  @ApiProperty({ type: 'string' })
  public SECOND_HELPING_1: string;

  /** Lorsque vous avez le choix, mangez-vous régulièrement après 21h ?*/
  @ApiProperty({ type: 'string' })
  public EATING_LATE_1: string;

  /** Mangez-vous rapidement ?*/
  @ApiProperty({ type: 'string' })
  public EATING_FAST_1: string;

  /** Avez-vous l'habitude de manger devant un écran ?*/
  @ApiProperty({ type: 'string' })
  public EATING_SCREEN_1: string;

  /** Pour les femmes en couple avec un homme, mangez vous la même quantité que votre compagnon ?*/
  @ApiProperty({ type: 'string' })
  public EAT_SAME_QUANTITY_AS_PARTNER_1: string;

  /** Merci de renseigner l'adresse email utilisée lors de l'inscription au programme.*/
  @ApiProperty({ type: 'string' })
  public EMAIL: string;

  /** Consommez-vous du lait ou des produits laitiers ?*/
  @ApiProperty({ type: 'string' })
  public FREQUENCY_DAIRY_PRODUCTS_1: string;

  /** Dont fromage(s) :*/
  @ApiProperty({ type: 'string' })
  public FREQUENCY_CHEESE_1: string;

  /** Consommez-vous de la viande rouge ?*/
  @ApiProperty({ type: 'string' })
  public FREQUENCY_MEAT_1: string;

  /** Consommez-vous de la charcuterie ?*/
  @ApiProperty({ type: 'string' })
  public FREQUENCY_COLD_MEATS_1: string;

  /** Consommez vous du foie et/ou des abats?*/
  @ApiProperty({ type: 'string' })
  public FREQUENCY_OFFAL_1: string;

  /** Consommez-vous de la volaille ?*/
  @ApiProperty({ type: 'string' })
  public FREQUENCY_POULTRY_1: string;

  /** Consommez-vous du poisson ?*/
  @ApiProperty({ type: 'string' })
  public FREQUENCY_FISH_1: string;

  /** Dont petits poissons gras ?*/
  @ApiProperty({ type: 'string' })
  public FREQUENCY_OILY_FISH_1: string;

  /** Consommez-vous des fruits de mer ?*/
  @ApiProperty({ type: 'string' })
  public FREQUENCY_SEA_PRODUCTS_1: string;

  /** Consommez-vous des oeufs ?*/
  @ApiProperty({ type: 'string' })
  public FREQUENCY_EGGS_1: string;

  /** Consommez-vous des céréales ou produits céréaliers ?*/
  @ApiProperty({ type: 'string' })
  public FREQUENCY_CEREAL_PRODUCTS_1: string;

  /** Consommez-vous des légumes secs  ?*/
  @ApiProperty({ type: 'string' })
  public FREQUENCY_LEGUMES_1: string;

  /** Consommez-vous des légumes crus/ crudités ?*/
  @ApiProperty({ type: 'string' })
  public FREQUENCY_RAW_VEGETABLES_1: string;

  /** Consommez-vous des légumes cuits ?*/
  @ApiProperty({ type: 'string' })
  public FREQUENCY_COOKED_VEGETABLES_1: string;

  /** Consommez-vous ces légumes, riches en B9 : épinards, salades vertes, brocolis, choux, asperges, betteraves*/
  @ApiProperty({ type: 'string' })
  public FREQUENCY_VEGETABLES_B9_1: string;

  /** Consommez-vous des fruits frais ? */
  @ApiProperty({ type: 'string' })
  public FREQUENCY_FRESH_FRUITS_1: string;

  /** Dont ces fruits riches en B9 : mangue, goyave, papaye, fraises */
  @ApiProperty({ type: 'string' })
  public FREQUENCY_FRESH_FRUITS_B9_1: string;

  /** Consommez-vous des herbes fraîches ? */
  @ApiProperty({ type: 'string' })
  public FREQUENCY_HERBS_1: string;

  /** Consommez-vous des graines ou fruits secs oléagineux ? */
  @ApiProperty({ type: 'string' })
  public FREQUENCY_OLEAGINOUS_1: string;

  /** Si vous utilisez régulièrement une ou plusieurs matières grasses pour la cuisson, quelles sont-elles ? */
  @ApiProperty({ type: 'string' })
  public COOKING_WITH_FAT_1: string;

  /** Si vous utilisez régulièrement une ou plusieurs matières grasses pour l'assaisonnement, quelles sont-elles ? */
  @ApiProperty({ type: 'string' })
  public SEASONING_WITH_FAT_1: string;

  /** Quelle(s) méthode(s) de cuisson privilégiez-vous ? */
  @ApiProperty({ type: 'string' })
  public COOKING_METHOD_1: string;

  /** Quelle quantité d'eau plate (minérale, de source ou du robinet, infusion) buvez-vous par jour ? */
  @ApiProperty({ type: 'string' })
  public FREQUENCY_STILL_WATER_1: string;

  /** Consommez-vous des eaux gazeuses ? */
  @ApiProperty({ type: 'string' })
  public FREQUENCY_SPARKLING_WATER_1: string;

  /** The actual mail sent to the user */
  @ApiProperty({ type: 'string' })
  public foodSurveyReport: string;

  @ApiProperty({ type: 'date' })
  createdDate: Date;

  @ApiProperty({ type: 'date' })
  updatedDate: Date;
}
