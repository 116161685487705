import { ApiProperty, IsString } from './decorators-nest';

export class CreateAssessmentSurveyDto {
  @ApiProperty()
  @IsString()
  FREQUENCY_SODAS_1: string;

  @ApiProperty()
  @IsString()
  FREQUENCY_COFFEE_1: string;

  @ApiProperty()
  @IsString()
  SUGARY_HOT_DRINKS_1: string;

  @ApiProperty()
  @IsString()
  FREQUENCY_SUGARY_FOODS_1: string;

  @ApiProperty()
  @IsString()
  FREQUENCY_FAST_FOOD_1: string;

  @ApiProperty()
  @IsString()
  FREQUENCY_PROCESSED_FOODS_1: string;

  @ApiProperty()
  @IsString()
  FREQUENCY_RESTAURANT_1: string;

  @ApiProperty()
  @IsString()
  FREQUENCY_DELIVERY_FOOD_1: string;

  @ApiProperty()
  @IsString()
  SALTING_MEALS_1: string;

  @ApiProperty()
  @IsString()
  CEREAL_PRODUCTS_WHOLE_GRAIN_1: string;

  @ApiProperty()
  @IsString()
  ORGANIC_FOOD_1: string;

  @ApiProperty()
  @IsString()
  HOME_MADE_FOOD_1: string;

  @ApiProperty()
  @IsString()
  FREQUENCY_ALCOHOL_1: string;

  @ApiProperty()
  @IsString()
  ALCOHOL_QUANTITY_1: string;

  @ApiProperty()
  @IsString()
  ALCOHOL_DISTRIBUTION_1: string;

  @ApiProperty()
  @IsString()
  ALCOHOL_COCKTAIL_1: string;

  @ApiProperty()
  @IsString()
  DIFFERENCE_HUNGER_CRAVING_1: string;

  @ApiProperty()
  @IsString()
  FOOD_CRAVING_1: string;

  @ApiProperty()
  @IsString()
  FOOD_CRAVING_AND_SNACKING_1: string;

  @ApiProperty()
  @IsString()
  KEEPS_EATING_WITHOUT_HUNGER_1: string;

  @ApiProperty()
  @IsString()
  SECOND_HELPING_1: string;

  @ApiProperty()
  @IsString()
  EATING_LATE_1: string;

  @ApiProperty()
  @IsString()
  EATING_FAST_1: string;

  @ApiProperty()
  @IsString()
  EATING_SCREEN_1: string;

  @ApiProperty()
  @IsString()
  EAT_SAME_QUANTITY_AS_PARTNER_1: string;

  @ApiProperty()
  @IsString()
  EMAIL: string;

  @ApiProperty()
  @IsString()
  FREQUENCY_DAIRY_PRODUCTS_1: string;

  @ApiProperty()
  @IsString()
  FREQUENCY_CHEESE_1: string;

  @ApiProperty()
  @IsString()
  FREQUENCY_MEAT_1: string;

  @ApiProperty()
  @IsString()
  FREQUENCY_COLD_MEATS_1: string;

  @ApiProperty()
  @IsString()
  FREQUENCY_OFFAL_1: string;

  @ApiProperty()
  @IsString()
  FREQUENCY_POULTRY_1: string;

  @ApiProperty()
  @IsString()
  FREQUENCY_FISH_1: string;

  @ApiProperty()
  @IsString()
  FREQUENCY_OILY_FISH_1: string;

  @ApiProperty()
  @IsString()
  FREQUENCY_SEA_PRODUCTS_1: string;

  @ApiProperty()
  @IsString()
  FREQUENCY_EGGS_1: string;

  @ApiProperty()
  @IsString()
  FREQUENCY_CEREAL_PRODUCTS_1: string;

  @ApiProperty()
  @IsString()
  FREQUENCY_LEGUMES_1: string;

  @ApiProperty()
  @IsString()
  FREQUENCY_RAW_VEGETABLES_1: string;

  @ApiProperty()
  @IsString()
  FREQUENCY_COOKED_VEGETABLES_1: string;

  @ApiProperty()
  @IsString()
  FREQUENCY_VEGETABLES_B9_1: string;

  @ApiProperty()
  @IsString()
  FREQUENCY_FRESH_FRUITS_1: string;

  @ApiProperty()
  @IsString()
  FREQUENCY_FRESH_FRUITS_B9_1: string;

  @ApiProperty()
  @IsString()
  FREQUENCY_HERBS_1: string;

  @ApiProperty()
  @IsString()
  FREQUENCY_OLEAGINOUS_1: string;

  @ApiProperty()
  @IsString()
  COOKING_WITH_FAT_1: string;

  @ApiProperty()
  @IsString()
  SEASONING_WITH_FAT_1: string;

  @ApiProperty()
  @IsString()
  COOKING_METHOD_1: string;

  @ApiProperty()
  @IsString()
  FREQUENCY_STILL_WATER_1: string;

  @ApiProperty()
  @IsString()
  FREQUENCY_SPARKLING_WATER_1: string;
}

export class AssessmentSurveyResponse extends CreateAssessmentSurveyDto {}
