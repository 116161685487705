import { ApiProperty, IsDateString, IsNotEmpty, IsOptional, IsString, OmitType } from './decorators-nest';

export class UtmConversionDto {
  @ApiProperty({
    description: 'UUID of the UtmConversion record',
    type: 'string',
    format: 'uuid',
    required: true,
    example: '124ed8db-25e5-47d7-a4f3-b1d9fa934650',
  })
  id: string;

  @ApiProperty({
    description: 'Affiliate/source identifier',
    required: true,
    example: 'my_affiliate_id',
  })
  utm_source: string;

  @ApiProperty({
    description: 'Medium identifier (affiliate, cpc, email...)',
    required: false,
    example: ['affiliate', 'cpc', 'email'],
  })
  utm_medium?: string;

  @ApiProperty({
    description: 'Identifier of a specific product promotion, strategic campaign.',
    required: false,
    example: 'home-banner',
  })
  utm_campaign?: string;

  @ApiProperty({
    description: 'Used for search. Use it to note the keywords for this link',
    required: false,
    example: 'pregnancy',
  })
  utm_term?: string;

  @ApiProperty({
    description:
      'Used for A/B testing and content-targeted links. Differentiates links that point to the same URL (bannerStyleA, bannerStyleB...)',
    required: false,
    example: 'bannerStyleA',
  })
  utm_content?: string;

  @ApiProperty({
    description: 'Creation timestamp',
    example: new Date(),
  })
  @IsDateString()
  createdDate?: Date;
}

/**
 * Create utm payload
 */
export class CreateUtmDto extends OmitType(UtmConversionDto, ['id', 'createdDate']) {
  @IsString()
  @IsNotEmpty()
  utm_source: string;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  utm_medium?: string;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  utm_campaign?: string;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  utm_term?: string;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  utm_content?: string;
}
