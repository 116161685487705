import { ApiProperty, IsBoolean, IsUUID, OmitType, ValidateIf } from './decorators-nest';

/**
 * Payload to create a Mood Survey
 * @todo: translate each description in english
 */
export class CreateMoodSurveyDto {
  @ApiProperty({
    description: 'related patient',
    format: 'uuid',
  })
  @IsUUID()
  patient: string;

  @ApiProperty({
    required: true,
    description: 'Baisse de moral',
  })
  @IsBoolean()
  MORALE_LOW: boolean;

  @ApiProperty({
    required: true,
    description: 'Moralement fatiguée',
  })
  @IsBoolean()
  MORALE_FATIGUE: boolean;

  @ApiProperty({
    required: false,
    description: 'Fatiguée moralement dès le réveil',
  })
  @IsBoolean()
  @ValidateIf((o: CreateMoodSurveyDto) => o.MORALE_FATIGUE === true)
  MORALE_FATIGUE_ON_WAKING: boolean;

  @ApiProperty({
    required: true,
    description: 'Déprimée',
  })
  @IsBoolean()
  DEPRESSED: boolean;

  @ApiProperty({
    required: true,
    description: 'Souffrance morale',
  })
  @IsBoolean()
  MORALE_SUFFERING: boolean;

  @ApiProperty({
    required: true,
    description: 'Rythme de vie ralenti',
  })
  @IsBoolean()
  SLOWED_DOWN_LIFE_RYTHM: boolean;

  @ApiProperty({
    required: true,
    description: 'Manque de motivation pour les projets',
  })
  @IsBoolean()
  LACK_MOTIVATION_PROJECTS: boolean;

  @ApiProperty({
    required: true,
    description: 'Manque de confiance en soi',
  })
  @IsBoolean()
  LACK_SELF_CONFIDENCE: boolean;

  @ApiProperty({
    required: true,
    description: "Manque d'intérêt pour ses occupations",
  })
  @IsBoolean()
  LACK_INTEREST_HOBBIES_LIBIDO: boolean;

  @ApiProperty({
    required: true,
    description: 'Irritable ou agressive',
  })
  @IsBoolean()
  IRRITABLE_AGRESSIVE: boolean;

  @ApiProperty({
    required: true,
    description: 'Humeur changeante',
  })
  @IsBoolean()
  CHANGEABLE_MOOD: boolean;
}

/**
 * Representation of a mood survey
 */
export class MoodSurveyDto extends OmitType(CreateMoodSurveyDto, ['patient'] as const) {
  @ApiProperty({
    type: 'string',
    format: 'uuid',
  })
  id: string;

  @ApiProperty({
    format: 'date-time',
  })
  createdDate: string;

  @ApiProperty({
    format: 'date-time',
  })
  updatedDate: string;
}

/**
 * Payload to create a Follow Up Mood Survey
 */
export class CreateFollowUpMoodSurveyDto extends CreateMoodSurveyDto {
  @ApiProperty({
    required: true,
    description: 'Evolution du moral',
  })
  @IsBoolean()
  CHANGE_STATE_MORALE: boolean;

  @ValidateIf((o: CreateFollowUpMoodSurveyDto) => o.CHANGE_STATE_MORALE === true)
  MORALE_LOW: boolean;

  @ValidateIf((o: CreateFollowUpMoodSurveyDto) => o.CHANGE_STATE_MORALE === true)
  MORALE_FATIGUE: boolean;

  @ValidateIf((o: CreateFollowUpMoodSurveyDto) => o.CHANGE_STATE_MORALE === true)
  MORALE_FATIGUE_ON_WAKING: boolean;

  @ValidateIf((o: CreateFollowUpMoodSurveyDto) => o.CHANGE_STATE_MORALE === true)
  DEPRESSED: boolean;

  @ValidateIf((o: CreateFollowUpMoodSurveyDto) => o.CHANGE_STATE_MORALE === true)
  MORALE_SUFFERING: boolean;

  @ValidateIf((o: CreateFollowUpMoodSurveyDto) => o.CHANGE_STATE_MORALE === true)
  SLOWED_DOWN_LIFE_RYTHM: boolean;

  @ValidateIf((o: CreateFollowUpMoodSurveyDto) => o.CHANGE_STATE_MORALE === true)
  LACK_MOTIVATION_PROJECTS: boolean;

  @ValidateIf((o: CreateFollowUpMoodSurveyDto) => o.CHANGE_STATE_MORALE === true)
  LACK_SELF_CONFIDENCE: boolean;

  @ValidateIf((o: CreateFollowUpMoodSurveyDto) => o.CHANGE_STATE_MORALE === true)
  LACK_INTEREST_HOBBIES_LIBIDO: boolean;

  @ValidateIf((o: CreateFollowUpMoodSurveyDto) => o.CHANGE_STATE_MORALE === true)
  IRRITABLE_AGRESSIVE: boolean;

  @ValidateIf((o: CreateFollowUpMoodSurveyDto) => o.CHANGE_STATE_MORALE === true)
  CHANGEABLE_MOOD: boolean;
}

/**
 * Payload to update a Sign Up Mood Survey
 */
export class UpdateMoodSurveyDto extends OmitType(CreateMoodSurveyDto, ['patient'] as const) {}
