import { IsDefined } from 'class-validator';
import {
  IsUrl,
  Max,
  MaxLength,
  ValidateNested,
  IsEmail,
  IsString,
  MinLength,
  IsNumber,
  Min,
  IsEnum,
  Type,
  IsOptional,
  IsDate,
} from './decorators-nest';
import { ApiProperty } from './decorators-nest';

enum BeMumPrograms {
  ESSENTIEL = 'essentiel',
  ESSENTIEL_PLUS = 'essentiel-plus',
  COACHING_PLUS = 'coaching-plus',
  ANTIOX = 'antiox',
  EQUILIBRE = 'equilibre',
  ANTIOX_PLUS = 'antiox-plus',
  EQUILIBRE_PLUS = 'equilibre-plus',
  COMPLETE = 'complet',
  COMPLET_PLUS = 'complet-plus',
  ABSOLU = 'absolu',
}

export enum AudienceSegmentationTags {
  AGE = '+35 ans',
  SOPK = 'sopk',
  ENDO = 'endo',
  MEDICAL_FOLLOWUP = 'suivi médical',
  BEMUM_V2 = 'bemum_v2',
  FERTILITY_TEST = 'resultat test',
  ABANDONNED_CART = 'panier abandonné',
  CLIENTS = 'clientes',
}

export class MergeFieldsDTO {
  @ApiProperty({
    description: 'Firstname of the subscriber.',
    example: 'john',
    required: true,
    maxLength: 255,
    minLength: 1,
  })
  @IsString()
  @MinLength(1)
  @MaxLength(255)
  FNAME: string;

  @ApiProperty({
    description: 'BeMum fertility score.',
    example: 5,
    required: true,
    minimum: 0,
    maximum: 10,
  })
  @IsNumber()
  @Min(0)
  @Max(10)
  BM_SCORE: number;

  @ApiProperty({
    description: 'Name of the recommended BeMum program.',
    example: 'coaching',
    enum: BeMumPrograms,
    type: 'enum',
    required: true,
  })
  @IsEnum(BeMumPrograms)
  BM_PG_NAME: BeMumPrograms;

  @ApiProperty({
    description: 'URL of the recommended BeMum program.',
    example: 'https://www.bemum.co/programme/programme-essentiel',
    required: true,
  })
  @IsUrl({
    require_tld: false,
  })
  BM_PG_LINK: string;

  @ApiProperty({
    description: '1st chunk of dynamic content (mail #1)',
    example:
      'Vous souhaitez perdre du poids pour préparer votre grossesse et démarrer celle-ci dans les meilleures conditions possibles. Très bon réflexe ! Une alimentation et des habitudes de vie saines vous permettront de tomber enceinte plus facilement et de limiter certains risques pour vous et votre futur bébé.',
    required: true,
    minLength: 1,
  })
  @IsString()
  @MinLength(1)
  BM_CNT_1: string;

  @ApiProperty({
    description: '2nd chunk of dynamic content (mail #1)',
    example:
      "Le lien entre alimentation, hygiène de vie, poids et fertilité est largement documenté scientifiquement. Vous nous avez fait part de votre diagnostic d'infertilité inexpliquée, le programme BeMum est spécifiquement conçu pour vous aider à perdre du poids et à identifier et mettre en place ces changements nécessaires pour améliorer votre fertilité.",
    minLength: 1,
  })
  @IsString()
  @MinLength(1)
  @IsOptional()
  BM_CNT_2: string;

  @ApiProperty({
    description: '3rd chunk of dynamic content (mail #1). It must be an HTML unordered list',
    example:
      '<ul><li>Votre indice de masse corporelle est trop élevé.</li><li>Vous vivez dans un environnement pollué.</li></ul>',
    required: true,
    minLength: 1,
  })
  @IsString()
  @MinLength(1)
  BM_CNT_3: string;

  @ApiProperty({
    description: '4th chunk of dynamic content (mail #1). It must be an HTML unordered list',
    example:
      "Votre comportement alimentaire participe à une prise de poids qui n'est pas souhaitable dans le cadre de votre projet bébé. Un travail en profondeur sur cet aspect est nécessaire pour vous permettre de retrouver un rapport sain à l'alimentation, et pouvoir mettre en place des habitudes alimentaires adaptées à votre projet de grossesse.",
    minLength: 1,
  })
  @IsString()
  @MinLength(1)
  @IsOptional()
  BM_CNT_4: string;
}

export class SubscribeToFertilityTestMarketingDTO {
  @ApiProperty({
    description: 'Email of the subscriber',
    example: 'foo@foo.com',
  })
  @IsEmail()
  email: string;

  @ApiProperty({
    description: 'Dynamic content to put in the emails',
    externalDocs: {
      url: 'https://mailchimp.com/help/getting-started-with-merge-tags/',
      description: 'See Mailchimp docs: "Getting Started with Merge Tags"',
    },
  })
  @ValidateNested()
  @Type(() => MergeFieldsDTO)
  mergeFields: MergeFieldsDTO;

  @IsDefined()
  @ApiProperty({
    description: `List of tags to segment audience ('+35 ans', 'sopk', 'endo', 'suivi médical')`,
    isArray: true,
  })
  tags: AudienceSegmentationTags[];
}

export class MergeFieldsV2DTO {
  @ApiProperty({
    description: 'Firstname of the subscriber.',
    example: 'john',
    required: true,
    maxLength: 255,
    minLength: 1,
  })
  @IsString()
  @MinLength(1)
  @MaxLength(255)
  FNAME: string;

  @ApiProperty({
    description: 'URL of the obtained results',
    example: 'https://www.bemum.co/resultats/programme-essentiel/',
    required: true,
  })
  @IsUrl({
    require_tld: false,
  })
  BM_RE_LINK: string;

  @ApiProperty({
    description: 'Boolean to indicate if abandoned cart had coaching option',
    example: 'true',
    required: true,
  })
  @IsString()
  W_COACHING: string;

  @ApiProperty({
    description: 'Boolean to indicate if abandoned cart had antiox option',
    example: 'true',
    required: true,
  })
  @IsString()
  W_ANTIOX: string;

  @ApiProperty({
    description: 'Boolean to indicate if abandoned cart had equilibre option',
    example: 'true',
    required: true,
  })
  @IsString()
  W_EQUILIBR: string;

  @ApiProperty({
    description: 'Boolean to indicate if abandoned cart prospect had more than 35 years',
    example: 'true',
    required: true,
  })
  @IsString()
  AGE_35: string;

  @ApiProperty({
    description: 'Boolean to indicate if abandoned cart prospect had sopk',
    example: 'true',
    required: true,
  })
  @IsString()
  SOPK: string;
}

export class SubscribeToFertilityTestMarketingV2DTO {
  @ApiProperty({
    description: 'Email of the subscriber',
    example: 'foo@foo.com',
  })
  @IsEmail()
  email: string;

  @ApiProperty({
    description: 'Dynamic content to put in the emails',
    externalDocs: {
      url: 'https://mailchimp.com/help/getting-started-with-merge-tags/',
      description: 'See Mailchimp docs: "Getting Started with Merge Tags"',
    },
  })
  @ValidateNested()
  @Type(() => MergeFieldsV2DTO)
  mergeFields: MergeFieldsV2DTO;

  @IsDefined()
  @ApiProperty({
    description: `List of tags to segment audience ('+35 ans', 'sopk', 'endo', 'suivi médical')`,
    isArray: true,
    enum: AudienceSegmentationTags,
  })
  tags: AudienceSegmentationTags[];
}

export class AbandonedCartMergeFieldsDTO {
  @ApiProperty({
    description: 'Firstname of the subscriber.',
    example: 'john',
    required: true,
    maxLength: 255,
    minLength: 1,
  })
  @IsString()
  @MinLength(1)
  @MaxLength(255)
  FNAME: string;

  @ApiProperty({
    description: 'Name of the recommended BeMum program.',
    example: 'coaching',
    enum: BeMumPrograms,
    type: 'enum',
    required: true,
  })
  @IsEnum(BeMumPrograms)
  BM_PG_NAME: BeMumPrograms;

  @ApiProperty({
    description: 'URL of the abandoned cart',
    example: 'https://www.bemum.co/panier?program=essentiel',
    required: true,
  })
  @IsUrl({
    require_tld: false,
  })
  BM_PG_LINK: string;

  @ApiProperty({
    description: 'Boolean to indicate if abandoned cart had coaching option',
    example: 'true',
    required: true,
  })
  @IsString()
  W_COACHING: string;

  @ApiProperty({
    description: 'Boolean to indicate if abandoned cart had antiox option',
    example: 'true',
    required: true,
  })
  @IsString()
  W_ANTIOX: string;

  @ApiProperty({
    description: 'Boolean to indicate if abandoned cart had equilibre option',
    example: 'true',
    required: true,
  })
  @IsString()
  W_EQUILIBR: string;
}

export class SubscribeToAbandonedCartMarketingDTO {
  @ApiProperty({
    description: 'Email of the subscriber',
    example: 'foo@foo.com',
  })
  @IsEmail()
  email: string;

  @ApiProperty({
    description: 'Dynamic content to put in the emails',
    externalDocs: {
      url: 'https://mailchimp.com/help/getting-started-with-merge-tags/',
      description: 'See Mailchimp docs: "Getting Started with Merge Tags"',
    },
  })
  @ValidateNested()
  @Type(() => AbandonedCartMergeFieldsDTO)
  mergeFields: AbandonedCartMergeFieldsDTO;

  @IsDefined()
  @ApiProperty({
    description: `List of tags to segment audience ('+35 ans', 'sopk', 'endo', 'suivi médical')`,
    isArray: true,
  })
  tags: AudienceSegmentationTags[];
}

export class ClientsMergeFieldsDTO {
  @ApiProperty({
    description: 'Firstname of the subscriber.',
    example: 'john',
    required: true,
    maxLength: 255,
    minLength: 1,
  })
  @IsString()
  @MinLength(1)
  @MaxLength(255)
  FNAME: string;

  @ApiProperty({
    description: 'Lastname of the subscriber.',
    example: 'smith',
    required: true,
    maxLength: 255,
    minLength: 1,
  })
  @IsString()
  @MinLength(1)
  @MaxLength(255)
  LNAME: string;

  @ApiProperty({
    description: 'Address of the subscriber.',
    example: '12, rue de la république',
    required: true,
    maxLength: 255,
    minLength: 1,
  })
  @IsString()
  @MinLength(1)
  @MaxLength(255)
  ADDRESS: string;

  @ApiProperty({
    description: 'Phone of the subscriber.',
    example: '0668686868',
    required: true,
    maxLength: 255,
    minLength: 1,
  })
  @IsString()
  @MinLength(1)
  @MaxLength(255)
  PHONE: string;
}

export class SubscribeToClientsMarketingDTO {
  @ApiProperty({
    description: 'Email of the subscriber',
    example: 'foo@foo.com',
  })
  @IsEmail()
  email: string;

  @ApiProperty({
    description: 'Dynamic content to put in the emails',
    externalDocs: {
      url: 'https://mailchimp.com/help/getting-started-with-merge-tags/',
      description: 'See Mailchimp docs: "Getting Started with Merge Tags"',
    },
  })
  @ValidateNested()
  @Type(() => ClientsMergeFieldsDTO)
  mergeFields: ClientsMergeFieldsDTO;
}
