import { ApiProperty } from './decorators-nest';

export class NutrinomeDto {
  @ApiProperty({
    type: 'string',
    format: 'uuid',
  })
  public id: string;

  // Descriptors
  @ApiProperty({
    type: 'integer',
  })
  STATE_MORALE_LEVEL: number;

  @ApiProperty({
    type: 'integer',
  })
  STATE_OXIDATIVE_STRESS_LEVEL: number;

  @ApiProperty({
    type: 'integer',
  })
  STATE_FATIGUE_LEVEL: number;

  @ApiProperty({
    type: 'integer',
  })
  STATE_ANXIETY_LEVEL: number;

  @ApiProperty({
    type: 'integer',
  })
  STATE_STRESS_LEVEL: number;

  // Parameters
  @ApiProperty()
  DAILY_NEED_PERSO_ALA: number;

  @ApiProperty()
  DAILY_NEED_PERSO_ANIMAL_PROTEINS: number;

  @ApiProperty()
  DAILY_NEED_PERSO_CALCIUM: number;

  @ApiProperty()
  DAILY_NEED_PERSO_CALORIES: number;

  @ApiProperty()
  DAILY_NEED_PERSO_CH: number;

  @ApiProperty()
  DAILY_NEED_PERSO_CHLORIDE: number;

  @ApiProperty()
  DAILY_NEED_PERSO_COPPER: number;

  @ApiProperty()
  DAILY_NEED_PERSO_DHA: number;

  @ApiProperty()
  DAILY_NEED_PERSO_EPA: number;

  @ApiProperty()
  DAILY_NEED_PERSO_FIBRE: number;

  @ApiProperty()
  DAILY_NEED_FOOD_ALA: number;

  @ApiProperty()
  DAILY_NEED_FOOD_ANIMAL_PROTEINS: number;

  @ApiProperty()
  DAILY_NEED_FOOD_CALCIUM: number;

  @ApiProperty()
  DAILY_NEED_FOOD_CALORIES: number;

  @ApiProperty()
  DAILY_NEED_FOOD_CH: number;

  @ApiProperty()
  DAILY_NEED_FOOD_CHLORIDE: number;

  @ApiProperty()
  DAILY_NEED_FOOD_COPPER: number;

  @ApiProperty()
  DAILY_NEED_FOOD_DHA: number;

  @ApiProperty()
  DAILY_NEED_FOOD_EPA: number;

  @ApiProperty()
  DAILY_NEED_FOOD_FIBRE: number;

  @ApiProperty()
  DAILY_NEED_FOOD_FLUORIDE: number;

  @ApiProperty()
  DAILY_NEED_FOOD_IODINE: number;

  @ApiProperty()
  DAILY_NEED_FOOD_IRON: number;

  @ApiProperty()
  DAILY_NEED_FOOD_LA: number;

  @ApiProperty()
  DAILY_NEED_FOOD_LIPIDS: number;

  @ApiProperty()
  DAILY_NEED_FOOD_MAGNESIUM: number;

  @ApiProperty()
  DAILY_NEED_FOOD_MANGANESE: number;

  @ApiProperty()
  DAILY_NEED_FOOD_OLEIC: number;

  @ApiProperty()
  DAILY_NEED_FOOD_PHOSPHORUS: number;

  @ApiProperty()
  DAILY_NEED_FOOD_POTASSIUM: number;

  @ApiProperty()
  DAILY_NEED_FOOD_PROTEINS: number;

  @ApiProperty()
  DAILY_NEED_FOOD_SELENIUM: number;

  @ApiProperty()
  DAILY_NEED_FOOD_SFA: number;

  @ApiProperty()
  DAILY_NEED_FOOD_SODIUM: number;

  @ApiProperty()
  DAILY_NEED_FOOD_VEGETABLE_PROTEINS: number;

  @ApiProperty()
  DAILY_NEED_FOOD_VIT_A: number;

  @ApiProperty()
  DAILY_NEED_FOOD_VIT_B1: number;

  @ApiProperty()
  DAILY_NEED_FOOD_VIT_B12: number;

  @ApiProperty()
  DAILY_NEED_FOOD_VIT_B2: number;

  @ApiProperty()
  DAILY_NEED_FOOD_VIT_B3: number;

  @ApiProperty()
  DAILY_NEED_FOOD_VIT_B5: number;

  @ApiProperty()
  DAILY_NEED_FOOD_VIT_B6: number;

  @ApiProperty()
  DAILY_NEED_FOOD_VIT_B8: number;

  @ApiProperty()
  DAILY_NEED_FOOD_VIT_B9: number;

  @ApiProperty()
  DAILY_NEED_FOOD_VIT_C: number;

  @ApiProperty()
  DAILY_NEED_FOOD_VIT_D: number;

  @ApiProperty()
  DAILY_NEED_FOOD_VIT_E: number;

  @ApiProperty()
  DAILY_NEED_FOOD_VIT_K: number;

  @ApiProperty()
  DAILY_NEED_FOOD_ZINC: number;

  @ApiProperty()
  DAILY_NEED_PERSO_IODINE: number;

  @ApiProperty()
  DAILY_NEED_PERSO_IRON: number;

  @ApiProperty()
  DAILY_NEED_PERSO_LA: number;

  @ApiProperty()
  DAILY_NEED_PERSO_LIPIDS: number;

  @ApiProperty()
  DAILY_NEED_PERSO_MAGNESIUM: number;

  @ApiProperty()
  DAILY_NEED_PERSO_MANGANESE: number;

  @ApiProperty()
  DAILY_NEED_PERSO_OLEIC: number;

  @ApiProperty()
  DAILY_NEED_PERSO_PHOSPHORUS: number;

  @ApiProperty()
  DAILY_NEED_PERSO_POTASSIUM: number;

  @ApiProperty()
  DAILY_NEED_PERSO_PROTEINS: number;

  @ApiProperty()
  DAILY_NEED_PERSO_SELENIUM: number;

  @ApiProperty()
  DAILY_NEED_PERSO_SFA: number;

  @ApiProperty()
  DAILY_NEED_PERSO_SODIUM: number;

  @ApiProperty()
  DAILY_NEED_PERSO_VEGETABLE_PROTEINS: number;

  @ApiProperty()
  DAILY_NEED_PERSO_VIT_A: number;

  @ApiProperty()
  DAILY_NEED_PERSO_VIT_B1: number;

  @ApiProperty()
  DAILY_NEED_PERSO_VIT_B12: number;

  @ApiProperty()
  DAILY_NEED_PERSO_VIT_B2: number;

  @ApiProperty()
  DAILY_NEED_PERSO_VIT_B3: number;

  @ApiProperty()
  DAILY_NEED_PERSO_VIT_B5: number;

  @ApiProperty()
  DAILY_NEED_PERSO_VIT_B6: number;

  @ApiProperty()
  DAILY_NEED_PERSO_VIT_B8: number;

  @ApiProperty()
  DAILY_NEED_PERSO_VIT_B9: number;

  @ApiProperty()
  DAILY_NEED_PERSO_VIT_C: number;

  @ApiProperty()
  DAILY_NEED_PERSO_VIT_D: number;

  @ApiProperty()
  DAILY_NEED_PERSO_VIT_E: number;

  @ApiProperty()
  DAILY_NEED_PERSO_VIT_K: number;

  @ApiProperty()
  DAILY_NEED_PERSO_ZINC: number;

  @ApiProperty()
  createdDate: Date;

  @ApiProperty()
  updatedDate: Date;
}
