import {
  ApiProperty,
  ApiPropertyOptional,
  IsDateString,
  IsEmail,
  IsEnum,
  IsNotEmpty,
  IsOptional,
  IsPhoneNumber,
  IsString,
  Matches,
  MinLength,
  PartialType,
} from './decorators-nest';

export enum StaffRole {
  ADMIN = 'admin',
  DIETITIAN = 'dietitian',
  SHIPPING = 'shipping',
  PROSPECTING = 'prospecting',
}

export enum Civility {
  MR = 'Mr',
  MRS = 'Mrs',
}

/** Properties that are shared between all kind of DTOs, the StaffDto, CreationStaffDto, UpdateStaffDto... */
class SharedStaffDto {
  @ApiProperty({ example: 'jane.doe@email.com' })
  @IsEmail()
  email: string;

  @ApiPropertyOptional({ example: 'Jane' })
  @IsString()
  @IsNotEmpty()
  firstname: string;

  @ApiPropertyOptional({ example: 'Doe' })
  @IsString()
  @IsNotEmpty()
  lastname: string;

  @ApiPropertyOptional({ example: '1992-09-29T12:26:12.354Z' })
  @IsDateString()
  @IsOptional()
  birthdate: Date;

  @ApiPropertyOptional({ enum: Civility, example: Civility.MRS })
  @IsEnum(Civility)
  @IsOptional()
  civility?: Civility;

  @ApiPropertyOptional({
    example: '+33123456789',
    description: `With the international prefix`,
    externalDocs: { url: `https://github.com/google/libphonenumber`, description: 'Validation rules' },
  })
  @IsPhoneNumber('FR')
  @IsOptional()
  phoneNumber?: string;

  @ApiPropertyOptional({
    description: 'RPPS number',
    example: '219501269123',
    externalDocs: {
      description: "Site de l'Agence du Numérique en Santé",
      url: 'https://esante.gouv.fr/securite/annuaire-sante/rpps-adeli',
    },
  })
  @IsString()
  @IsOptional()
  rpps?: string;

  @ApiProperty({ enum: StaffRole, example: StaffRole.DIETITIAN })
  @IsEnum(StaffRole)
  role: StaffRole;
}

/** Staff interface */
export class StaffDto extends SharedStaffDto {
  @ApiProperty({ example: '124ed8db-25e5-47d7-a4f3-b1d9fa934650' })
  id: string;

  @ApiProperty({ example: '2021-07-20T11:33:58.867Z' })
  createdDate: Date;

  @ApiProperty({ example: '2021-07-20T11:33:58.867Z' })
  updatedDate: Date;
}

/** Staff creation payload */
export class CreateStaffDto extends SharedStaffDto {
  @ApiProperty({
    description: `Minimum length 8 chars AND 3 of the 4 categories : lowercase, uppercase, number, special chars`,
    example: 'RCsYZ5xZTrjzxCZyrT',
    minLength: 8,
    pattern:
      '(((?=.*W+)(?=.*[A-Z])(?=.*[a-z]))|((?=.*d)(?=.*[A-Z])(?=.*[a-z]))|((?=.*d)(?=.*W+)(?=.*[a-z]))|((?=.*d)(?=.*W+)(?=.*[A-Z]))).*$',
  })
  @IsString()
  @MinLength(8)
  @Matches(
    /(((?=.*\W+)(?=.*[A-Z])(?=.*[a-z]))|((?=.*\d)(?=.*[A-Z])(?=.*[a-z]))|((?=.*\d)(?=.*\W+)(?=.*[a-z]))|((?=.*\d)(?=.*\W+)(?=.*[A-Z]))).*$/,
    { message: 'password must contain at least 3 of the 4 categories : lowercase, uppercase, number, special chars' }
  )
  password: string;
}

/** Staff update payload */
export class UpdateStaffDto extends PartialType(CreateStaffDto) {}
